import { isElementInViewport } from "@flixbus-phx/marketplace-common";
import cx from "classnames";
import * as React from "react";
import Portal from "../portal/Portal";
import * as css from "./TooltipPortal.scss";

type Props = {
  invertedColors: boolean;
  positionX: number;
  positionYTop: number;
  positionYBottom: number;
  moveXpositionWithMouse?: boolean;
  children: React.ReactNode;
};

const TooltipPortal: React.FC<Props> = ({
  invertedColors,
  positionX,
  positionYTop,
  positionYBottom,
  moveXpositionWithMouse,
  children,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [isTooltipPositionBottom, setIsTooltipPositionBottom] = React.useState(true);

  React.useEffect(() => {
    if (ref.current) {
      setIsTooltipPositionBottom(isElementInViewport(ref)[2]);
    }
  }, []);

  React.useEffect(() => {
    const updatePosition = (e: MouseEvent | WheelEvent) => {
      if (ref.current && moveXpositionWithMouse) {
        ref.current.style.left = `${e.clientX}px`;
      }
    };

    document.addEventListener("mousemove", updatePosition);
    document.addEventListener("wheel", updatePosition);

    return () => {
      document.removeEventListener("mousemove", updatePosition);
      document.removeEventListener("wheel", updatePosition);
    };
  });

  return (
    <Portal portalId="portal-root">
      <div
        ref={ref}
        style={{
          left: moveXpositionWithMouse
            ? ref.current?.style.left
              ? undefined
              : positionX
            : positionX,
          top: isTooltipPositionBottom ? positionYTop : undefined,
          bottom: isTooltipPositionBottom
            ? undefined
            : window.innerHeight - positionYBottom,
        }}
        className={cx(
          css.tooltipWrapper,
          invertedColors ? css.darkDesign : css.lightDesign,
          isTooltipPositionBottom ? css.positionBottom : css.positionTop
        )}
      >
        <div className={css.children}>{children}</div>
      </div>
    </Portal>
  );
};

export default TooltipPortal;
export const MemoizedTooltipPortal = React.memo(
  TooltipPortal,
  // prevents constant rerender when scrolling horizontally
  (prevProps, nextProps) => {
    return prevProps.positionX === nextProps.positionX;
  }
);
