import { IconArrowDown, IconPersonSolid } from "@flixbus/honeycomb-icons-react";
import {
  addNotification,
  DefaultInfoAlert,
  Feature,
  formatErrorMessage,
  hasUserPermission,
  NotificationType,
  mpcPathnames as pathnames,
} from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import DetailSummary from "../../shared/components/detailSummary/DetailSummary";
import DetailSummarySkeleton from "../../shared/components/detailSummarySkeleton/DetailSummarySkeleton";
import HorizontalNavigation, {
  HorizontalNavigationDisplayedTab,
} from "../../shared/components/horizontalNavigation/HorizontalNavigation";
import getEditablePartnerFields from "../../shared/helpers/getEditableFormFields/getEditablePartnerFields/getEditablePartnerFields";
import stripTypename from "../../shared/helpers/stripTypename/stripTypename";
import PartnerAccountForm from "../partnerAccountForm/PartnerAccountForm";
import PartnerBrandsAndPoliciesForm from "../partnerBrandsAndPoliciesForm/PartnerBrandsAndPoliciesForm";
import PartnerCapacityAndReportsForm from "../partnerCapacityAndReportsForm/PartnerCapacityAndReportsForm";
import PartnerCustomerServiceAndPoliciesForm from "../partnerCustomerServiceAndPoliciesForm/PartnerCustomerServiceAndPoliciesForm";
import PartnerSettingsForm from "../partnerSettingsForm/PartnerSettingsForm";
import UserRoleAssignment from "../userRoleAssignment/UserRoleAssignment";
import {
  UpdatePartnerMutationVariables,
  useDeletePartnerMutation,
  useFindMobilityPartnerDetailsQuery,
  usePartnerDetailsQuery,
  useUpdateMobilityPartnerMutation,
  useUpdatePartnerMutation,
} from "./api/operations.generated";
import generatePartnerDetailTags from "./helpers/generatePartnerDetailTags/generatePartnerDetailTags";
import CustomPartnerTags from "./ui/customPartnerTags/CustomPartnerTags";
import PartnerDetailFormButtons from "./ui/partnerDetailFormButtons/PartnerDetailFormButtons";

type Props = {
  partnerId?: string;
};

const PartnerDetail: React.FC<Props> = ({ partnerId }) => {
  const { formatMessage } = useIntl();
  const [editMode, setEditMode] = React.useState(false);

  const navigate = useNavigate();

  const [tabDisplayed, setTabDisplayed] = React.useState(
    HorizontalNavigationDisplayedTab.ONE
  );

  React.useEffect(() => {
    if (!editMode) {
      setTabDisplayed(HorizontalNavigationDisplayedTab.ONE);
    }
  }, [editMode]);

  const {
    data: partnerData,
    loading: queryLoading,
    error: queryError,
  } = usePartnerDetailsQuery({
    variables: partnerId ? { partnerId } : undefined,
  });

  const {
    data: mobilityPartnerData,
    loading: queryMobilityPartnerLoading,
    error: queryMobilityPartnerError,
  } = useFindMobilityPartnerDetailsQuery({
    variables: partnerId ? { partnerId } : undefined,
    skip: !hasUserPermission(Feature.EDIT_PARTNER_SETTINGS),
  });

  const [updatePartner, { loading: partnerMutationLoading }] = useUpdatePartnerMutation({
    onError: (error) =>
      addNotification({
        message: `Partner update failed: ${formatErrorMessage(error.message)}`,
        type: NotificationType.Danger,
      }),
    onCompleted: () => {
      addNotification({
        message: formatMessage({ id: "partnerDetail.submit.notification.success" }),
      });
      setEditMode(false);
    },
  });

  const [updateMobilityPartner, { loading: mobilityPartnerMutationLoading }] =
    useUpdateMobilityPartnerMutation({
      onError: (error) =>
        addNotification({
          message: `Mobility partner update failed: ${formatErrorMessage(error.message)}`,
          type: NotificationType.Danger,
        }),
      onCompleted: () => {
        addNotification({
          message: formatMessage({
            id: "partnerForm.interconnectionRules.operator.update.success",
          }),
        });
      },
    });

  const handleUpdatePartner = (values: Partial<UpdatePartnerMutationVariables>) => {
    if (partnerData?.findPartner.id) {
      updatePartner({
        variables: {
          id: partnerData.findPartner.id,
          ...values,
        },
      });
    }
  };

  const [partnerDelete] = useDeletePartnerMutation({
    onError: (error) =>
      addNotification({
        message: `Deleting partner failed: ${formatErrorMessage(error.message)}`,
        type: NotificationType.Danger,
      }),
    onCompleted: () => {
      navigate(pathnames.userHome);
    },
  });

  const handlePartnerDelete = () => {
    if (partnerData?.findPartner.id) {
      partnerDelete({
        variables: { id: partnerData.findPartner.id },
      });
    }
  };

  if (queryLoading || queryMobilityPartnerLoading) {
    return <DetailSummarySkeleton addTopMargin />;
  }

  if (partnerData?.findPartner && !queryMobilityPartnerError) {
    const partner = partnerData.findPartner;
    const mobilityPartner = mobilityPartnerData?.findMobilityPartner;

    const editableFields = getEditablePartnerFields(partner.readOnly, false);

    const FormButtons = (
      <PartnerDetailFormButtons
        isLoading={partnerMutationLoading || mobilityPartnerMutationLoading}
        isEditable={editableFields.submitButton}
        isDeleteButtonShown={editableFields.deleteButton.isShown}
        isDeleteButtonEnabled={editableFields.deleteButton.isEditable}
        onDelete={handlePartnerDelete}
        onClose={() => setEditMode(false)}
        partnerName={partner.name}
        isOutsideOfFormik={false}
      />
    );

    return (
      <DetailSummary
        isEditMode={editMode}
        boxIcon={IconPersonSolid}
        boxContent={{
          heading: partner.name,
          firstSubtitle: partner.businessRegion.name,
        }}
        tags={generatePartnerDetailTags(partner, mobilityPartner)}
        cutomTags={
          hasUserPermission(Feature.VIEW_PARTNER_TAGS) ? (
            <CustomPartnerTags
              partnerId={partner.id}
              readOnly={!editMode}
              tags={partner.tags}
            />
          ) : undefined
        }
        onOpen={() => setEditMode(true)}
        onClose={() => setEditMode(false)}
        editIcon={editableFields.submitButton ? undefined : IconArrowDown}
        addTopMargin
      >
        <HorizontalNavigation
          displayedTab={tabDisplayed}
          onTabClicked={(clickedTab) => setTabDisplayed(clickedTab)}
          labels={[
            {
              text: formatMessage({ id: "partnerForm.tab.account" }),
              position: HorizontalNavigationDisplayedTab.ONE,
            },
            {
              text: formatMessage({ id: "partnerForm.tab.capacityAndReports.v2" }),
              position: HorizontalNavigationDisplayedTab.TWO,
            },
            {
              text: formatMessage({ id: "partnerForm.tab.customerServiceAndPolicies" }),
              position: HorizontalNavigationDisplayedTab.THREE,
            },
            {
              text: formatMessage({ id: "partnerForm.tab.brandsAndPolicies" }),
              position: HorizontalNavigationDisplayedTab.FOUR,
            },
            {
              text: formatMessage({ id: "general.settings" }),
              position: HorizontalNavigationDisplayedTab.FIVE,
            },
            {
              text: formatMessage({ id: "partnerForm.tab.team" }),
              position: HorizontalNavigationDisplayedTab.SIX,
            },
          ].filter((value) => {
            if (
              value.position !== HorizontalNavigationDisplayedTab.FIVE ||
              editableFields.settingsInput.isApiPartner ||
              editableFields.settingsInput.allOtherSettings ||
              editableFields.settingsInput.icPricingRules
            ) {
              return true;
            }
            return false;
          })}
        />

        {tabDisplayed === HorizontalNavigationDisplayedTab.ONE && (
          <PartnerAccountForm
            defaultValues={stripTypename({
              name: partner.name,
              businessRegionId: partner.businessRegion.id,
              concessionOwnerId: partner.concessionOwnerId,
              businessDevelopers: partner.businessDevelopers,
              accessPackage: partner.accessPackage,
              meansOfTransport: partner.meansOfTransport,
              currency: partner.currency,
            })}
            onSubmit={(values) => handleUpdatePartner({ accountInput: values })}
            onCancel={() => setEditMode(false)}
            editableFields={editableFields.accountInput}
            isCreate={false}
          >
            {FormButtons}
          </PartnerAccountForm>
        )}

        {tabDisplayed === HorizontalNavigationDisplayedTab.TWO && (
          <PartnerCapacityAndReportsForm
            defaultValues={stripTypename({
              capacityDecreaseTimeFrameInHours: partner.capacityDecreaseTimeFrameInHours,
              dataPrivacyContractSigned: partner.dataPrivacyContractSigned,
              bookingInformationConfiguration: partner.bookingInformationConfiguration,
              defaultCirculationId: partner.defaultCirculation?.uuid,
            })}
            onSubmit={(values) =>
              handleUpdatePartner({ capacityAndReportsInput: values })
            }
            onCancel={() => setEditMode(false)}
            editableFields={editableFields.capacityAndReportsInput}
            partnerId={partner.id}
          >
            {FormButtons}
          </PartnerCapacityAndReportsForm>
        )}

        {tabDisplayed === HorizontalNavigationDisplayedTab.THREE && (
          <PartnerCustomerServiceAndPoliciesForm
            defaultValues={stripTypename({
              checkInRequirements: partner.checkInRequirements,
              customerServiceContact: partner.customerServiceContact,
              termsAndConditionsUrl: partner.termsAndConditionsUrl,
            })}
            onSubmit={(values) =>
              handleUpdatePartner({ customerServiceAndPoliciesInput: values })
            }
            onCancel={() => setEditMode(false)}
            editableFields={editableFields.customerServiceAndPoliciesInput}
          >
            {FormButtons}
          </PartnerCustomerServiceAndPoliciesForm>
        )}
        {tabDisplayed === HorizontalNavigationDisplayedTab.FOUR && (
          <PartnerBrandsAndPoliciesForm
            defaultValues={{
              brands: partner.brands.map((brand) => stripTypename(brand)),
            }}
            onSubmit={(values) => handleUpdatePartner({ brandsInput: values })}
            onCancel={() => setEditMode(false)}
            editableFields={editableFields.brandsInput}
          >
            {FormButtons}
          </PartnerBrandsAndPoliciesForm>
        )}
        {tabDisplayed === HorizontalNavigationDisplayedTab.FIVE &&
          (editableFields.settingsInput.isApiPartner ||
            editableFields.settingsInput.allOtherSettings ||
            editableFields.settingsInput.icPricingRules) && (
            <PartnerSettingsForm
              defaultValues={stripTypename({
                totalInterconnectionPricing: partner.totalInterconnectionPricing,
                priceChangeAllowed: partner.priceChangeAllowed,
                externalInterconnectionPricingAllowed:
                  partner.externalInterconnectionPricingAllowed,
                apiSettings: partner.apiSettings,
                autoOnSaleConfig: partner.autoOnSaleConfig,
                inventoryRule: mobilityPartner
                  ? {
                      inventoryRestriction: mobilityPartner.inventoryRestriction,
                      interconnectionRule: mobilityPartner.interconnectionRule,
                    }
                  : undefined,
              })}
              onSubmit={(values) => {
                handleUpdatePartner({
                  settingsInput: {
                    totalInterconnectionPricing: values.totalInterconnectionPricing,
                    priceChangeAllowed: values.priceChangeAllowed,
                    externalInterconnectionPricingAllowed:
                      values.externalInterconnectionPricingAllowed,
                    apiSettings: values.apiSettings,
                    autoOnSaleConfig: values.autoOnSaleConfig,
                  },
                });

                if (mobilityPartner) {
                  updateMobilityPartner({
                    variables: {
                      id: mobilityPartner.uuid,
                      inventoryRule: values.inventoryRule,
                    },
                  });
                }
              }}
              onCancel={() => setEditMode(false)}
              hasMobilityPartner={!!mobilityPartner}
              isCreation={false}
              editableFields={editableFields.settingsInput}
            >
              {FormButtons}
            </PartnerSettingsForm>
          )}
        {tabDisplayed === HorizontalNavigationDisplayedTab.SIX && (
          <UserRoleAssignment
            partnerId={partnerId}
            accessPackage={partner.accessPackage}
            isEditable={editableFields.teamInput}
          >
            <PartnerDetailFormButtons
              isLoading={false}
              isEditable={editableFields.submitButton}
              isDeleteButtonShown={editableFields.deleteButton.isShown}
              isDeleteButtonEnabled={editableFields.deleteButton.isEditable}
              onDelete={handlePartnerDelete}
              onClose={() => setEditMode(false)}
              partnerName={partner.name}
              isOutsideOfFormik
            />
          </UserRoleAssignment>
        )}
      </DetailSummary>
    );
  }

  if (queryError) {
    return (
      <DefaultInfoAlert
        message={formatMessage(
          { id: "partnerDetail.error.partnerNotFound" },
          { partnerId }
        )}
      />
    );
  }

  if (queryMobilityPartnerError) {
    return (
      <DefaultInfoAlert
        message={`Unable to find mobility partner for partner with id: ${partnerId}`}
      />
    );
  }

  return <></>;
};

export default PartnerDetail;
