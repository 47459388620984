import { Icon, IconNewTab } from "@flixbus/honeycomb-icons-react";
import { Box, Heading, Link } from "@flixbus/honeycomb-react";
import {
  addNotification,
  DefaultInfoAlert,
  Feature,
  formatErrorMessage,
  hasUserPermission,
  NotificationType,
  mpcPathnames as pathnames,
} from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import hasEditStationPermission from "../../../../shared/helpers/hasEditStationPermission/hasEditStationPermission";
import stripTypename from "../../../../shared/helpers/stripTypename/stripTypename";
import { MutationNewStationArgs, StationStatus } from "../../../../shared/types/schema";
import StationActions from "../../../citiesAndStationsTab/container/stationActions/StationActions";
import StationStatusTag from "../../../citiesAndStationsTab/ui/stationStatusTag/StationStatusTag";
import StationForm from "../../../stationForm/StationForm";
import StationFormButtons from "../../../stationFormButtons/StationFormButtons";
import {
  useFindStationQuery,
  useUpdateStationMutation,
} from "../../api/operations.generated";
import StationDetailLoading from "../../ui/stationDetailLoading/StationDetailLoading";
import * as css from "./StationTab.scss";

type Props = {
  stationId: string;
  onPanelClose: () => void;
  hideFormCloseButton?: boolean;
};

const StationTab: React.FC<Props> = ({
  stationId,
  onPanelClose,
  hideFormCloseButton,
}) => {
  const { formatMessage } = useIntl();

  const {
    data: stationData,
    loading: queryLoading,
    error: queryError,
  } = useFindStationQuery({
    variables: { stationId },
  });

  const [updateStation, { loading }] = useUpdateStationMutation({
    onCompleted: () => {
      addNotification({
        message: formatMessage({
          id: "station.detailPanel.station.update.notification.success",
        }),
      });
    },
    onError: (error) =>
      addNotification({
        message: `Station update failed: ${formatErrorMessage(error.message)}`,
        type: NotificationType.Danger,
      }),
  });

  const handleStationChange = (values: MutationNewStationArgs) => {
    updateStation({
      variables: { id: stationId, ...values },
    });
    onPanelClose();
  };

  if (queryLoading) {
    return <StationDetailLoading />;
  }

  if (stationData) {
    const station = stationData.findStation;

    const readOnly = !hasEditStationPermission(station.status);

    return (
      <>
        {station.rejectionReason && (
          <Box data-id="rejectReasonBox" appearance="danger">
            <Heading size={4} Elem="strong">
              <FormattedMessage id="station.rejectionReason" />:
            </Heading>
            <p className={css.rejectionReason}>{station.rejectionReason}</p>

            {station.alternativeStation && (
              <div data-id="alternativeStation" className={css.alternativeStationWrapper}>
                <span>
                  <FormattedMessage id="station.alternativeStation.info.label" />
                </span>
                <span> :</span>
                <Link
                  target="_blank"
                  href={`${pathnames.showStations}?station_id=${station.alternativeStation.id}&station_name=${station.alternativeStation.name}`}
                >
                  <div className={css.linkWrapper}>
                    <span className={css.alternativeStationLabel}>
                      {station.alternativeStation.name}
                    </span>
                    <Icon
                      extraClasses={css.newTabIcon}
                      InlineIcon={IconNewTab}
                      aria-label="Opens in a New Tab"
                    />
                  </div>
                </Link>
              </div>
            )}
          </Box>
        )}
        <div className={css.tagContainer}>
          <StationStatusTag status={station.status} />
        </div>

        <StationForm
          onSubmit={handleStationChange}
          onCancel={onPanelClose}
          defaultValues={stripTypename({
            ...station,
            city: station.city
              ? {
                  id: station.city.value,
                }
              : undefined,
          })}
          readOnly={readOnly}
          submitLoading={loading}
          hasNearbyStation={station.hasNearbyStation ? station.hasNearbyStation : false}
        >
          {station.status === StationStatus.InReview &&
          hasUserPermission(Feature.EDIT_STATION_APPROVE_REJECT) ? (
            <></>
          ) : (
            <StationFormButtons
              submitButtonText={formatMessage({ id: "general.confirm" })}
              usedInPanel
              readOnly={readOnly}
              hideCloseButton={hideFormCloseButton}
              submitLoading={loading}
            />
          )}
        </StationForm>
        <div className={css.stationActions}>
          <StationActions stationId={station.id} status={station.status} />
        </div>
      </>
    );
  }

  if (queryError) {
    return (
      <DefaultInfoAlert
        message={formatMessage(
          { id: "station.detailPanel.station.error.notAbleToLoad" },
          {
            stationId,
          }
        )}
      />
    );
  }

  return <></>;
};

export default StationTab;
