import { Tag } from "@flixbus/honeycomb-react";
import cx from "classnames";
import * as React from "react";
import getAggregatedScheduleStatus from "../../helpers/getAggregatedScheduleStatus/getAggregatedScheduleStatus";
import { DisplayedScheduleStatus } from "../../types/schema";
import * as css from "./ScheduleStatusTag.scss";

type Props = {
  status: DisplayedScheduleStatus;
  isOutdated?: boolean;
  extraClasses?: string;
  isSmall: boolean;
};

const ScheduleStatusTag: React.FC<Props> = ({
  status,
  isOutdated = false,
  extraClasses,
  isSmall,
  children,
}) => {
  const restProps = {
    small: isSmall,
    extraClasses,
  };

  const aggregatedScheduleStatus = getAggregatedScheduleStatus(status);

  return (
    <Tag
      disabled={isOutdated}
      {...restProps}
      extraClasses={cx(restProps.extraClasses, css[aggregatedScheduleStatus])}
    >
      {children}
    </Tag>
  );
};

export default ScheduleStatusTag;
