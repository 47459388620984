import { isSameDay, isSameMonth } from "date-fns";
import { Calendar, CalendarIntervalType } from "../../types";

const getAllStartIndexesPerRow = (
  calendar: Calendar,
  displayDates: Date[]
): number[][] => {
  return calendar.rows.map((row) => {
    const cols: number[] = [];

    row.chunks.forEach((chunk) => {
      const index = displayDates.findIndex(
        (date) =>
          (calendar.intervalType === CalendarIntervalType.MONTHLY
            ? isSameMonth(date, chunk.uiPeriod.start)
            : isSameDay(date, chunk.uiPeriod.start)) && chunk.isFirstOccurrence
      );

      if (index > -1) {
        cols.push(index);
      }
    });

    return cols;
  });
};

export default getAllStartIndexesPerRow;
