import React__default__default from 'react';
import { useBrowser } from '../use-browser/use-browser.js';

/* eslint-disable max-len */
function useBodyStyleModifications(_a) {
  var active = _a.active;
  var _b = useBrowser(),
    doc = _b[1];
  /**
   * When the modal is opened should block users from scrolling the document body.
   * This does not happen when is not modal (hasOverlay = false) or if customer has disabled it.
   */
  React__default__default.useEffect(function () {
    if (doc !== null) {
      var scrollbarWidth = Math.abs(doc.defaultView.innerWidth - doc.documentElement.clientWidth);
      var scrollBarInPix = "".concat(scrollbarWidth, "px");
      if (active) {
        doc.body.style.overflow = 'hidden';
        doc.body.style.paddingRight = scrollBarInPix;
      } else {
        doc.body.style.removeProperty('overflow');
        doc.body.style.removeProperty('padding-right');
      }
    }
    // removing overflow when component is unmounted
    return function () {
      if (doc !== null) {
        var scrollbarWidth = Math.abs(doc.defaultView.innerWidth - doc.documentElement.clientWidth);
        var scrollBarInPix = "".concat(scrollbarWidth, "px");
        if (doc.body.style.overflow === 'hidden') {
          doc.body.style.removeProperty('overflow');
        }
        if (doc.body.style.paddingRight === scrollBarInPix) {
          doc.body.style.removeProperty('padding-right');
        }
      }
    };
  }, [doc, active]);
}

export { useBodyStyleModifications };
