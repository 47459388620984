import { startOfMonth, endOfMonth, startOfYear, endOfYear, interval } from "date-fns";
import { Calendar, CalendarIntervalType } from "../../types";
import growInterval from "../buildCalendar/buildCalendar";

const calculateCalendarBoundary = (calendar: Calendar, expanded: number[]) => {
  if (calendar.rows.length === 0) {
    return interval(new Date(), new Date());
  }

  let boundary = interval(
    calendar.rows[0].chunks[0].uiPeriod.start,
    calendar.rows[0].chunks[0].uiPeriod.end
  );

  calendar.rows.forEach((row, rowIndex) => {
    row.chunks.forEach((chunk) => {
      boundary = growInterval(boundary, chunk.uiPeriod);
    });
    if (row.hasInvisibleConceptChanges && expanded.includes(rowIndex)) {
      row.invisibleConceptChanges.forEach((cc) => {
        boundary = growInterval(boundary, cc.uiPeriod);
      });
    }
  });

  return calendar.intervalType === CalendarIntervalType.DAILY
    ? interval(startOfMonth(boundary.start), endOfMonth(boundary.end))
    : interval(startOfYear(boundary.start), endOfYear(boundary.end));
};

export default calculateCalendarBoundary;
