@use "variables" as *;

.tooltipWrapper {
  position: fixed;
  z-index: 200;
  transform: translateX(-50%);

  &::before {
    top: $spacing-2;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    position: absolute;
    z-index: 0;
    border-radius: $border-radius;
    box-shadow: $primary-box-shadow;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: 0;
    width: 0;
    height: 0;
    transform: translateX(-50%);
    left: 50% !important;
  }
}

.positionBottom {
  padding: $spacing-4 0 $spacing-2 0;

  &::before {
    top: $spacing-2;
    bottom: 0;
  }

  &::after {
    inset: $spacing-1 auto auto auto;
    border-top: 0;
    border-right: calc($spacing-1 + 1px) solid;
    border-bottom: calc($spacing-1 + 1px) solid;
    border-left: calc($spacing-1 + 1px) solid;
  }
}

.positionTop {
  padding: $spacing-2 0 $spacing-4 0;

  &::before {
    bottom: $spacing-2;
    top: 0;
  }

  &::after {
    inset: auto auto $spacing-1 auto;
    border-top: calc($spacing-1 + 1px) solid;
    border-right: calc($spacing-1 + 1px) solid;
    border-bottom: 0;
    border-left: calc($spacing-1 + 1px) solid;
  }
}

.children {
  position: relative;
}

.lightDesign {
  &::before {
    background: $box-bg-color;
  }

  &.positionBottom::after {
    border-right-color: rgba(0, 0, 0, 0);
    border-bottom-color: $box-bg-color;
    border-left-color: rgba(0, 0, 0, 0);
  }

  &.positionTop::after {
    border-right-color: rgba(0, 0, 0, 0);
    border-top-color: $box-bg-color;
    border-left-color: rgba(0, 0, 0, 0);
  }
}

.darkDesign {
  color: $bg-primary-color;

  &::before {
    background: $header-color;
  }

  &.positionBottom::after {
    border-right-color: rgba(255, 255, 255, 0);
    border-bottom-color: $header-color;
    border-left-color: rgba(255, 255, 255, 0);
  }

  &.positionTop::after {
    border-right-color: rgba(255, 255, 255, 0);
    border-top-color: $header-color;
    border-left-color: rgba(255, 255, 255, 0);
  }
}
