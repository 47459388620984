@use "variables" as *;
@use "../../styles/schedule-status.scss";

.DRAFT {
  background: schedule-status.$draftBackground;
  border: schedule-status.$draftBorder;
}

.IN_REVIEW {
  background: schedule-status.$inReviewBackground;
  border: schedule-status.$inReviewBorder;
}

.PROCESSING {
  background: schedule-status.$processingBackground;
  border: none;
}

.HIDDEN {
  background: schedule-status.$hiddenBackground;
  border: none;

  [class^="hcr-theme-flix-dark"] & {
    color: #353535;
  }
}

.ON_SALE {
  background: schedule-status.$onSaleBackground;
  border: none;

  [class^="hcr-theme-flix-dark"] & {
    color: #353535;
  }
}

.INACTIVE {
  background: schedule-status.$inactiveBackground;
  border: none;
}
