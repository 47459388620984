import { useReactiveVar } from "@apollo/client";
import { DefaultInfoAlert } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import LoadingAnimation from "../../shared/components/loadingAnimation/LoadingAnimation";
import { Line } from "../../shared/types/schema";
import showInactiveSchedulesVar from "../../state/showInactiveSchedulesVar/showInactiveSchedulesVar";
import { useOriginalSchedulesOfLineQuery } from "./api/operations.generated";
import NoSchedulesFoundMessage from "./ui/noSchedulesFoundMessage/NoSchedulesFoundMessage";
import ScheduleCalendarShell from "./ui/scheduleCalendarShell/ScheduleCalendarShell";

export type ScheduleCalendarProps = {
  lineId: Line["id"];
};

const ScheduleCalendar: React.FC<ScheduleCalendarProps> = ({ lineId }) => {
  const showInactive = useReactiveVar(showInactiveSchedulesVar);

  const { loading, error, data } = useOriginalSchedulesOfLineQuery({
    variables: { lineId, excludeInactive: !showInactive },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: false,
  });

  if (loading) {
    return <LoadingAnimation />;
  }
  if (error) {
    return <DefaultInfoAlert message={error.message} />;
  }

  if (data) {
    if (data.findOriginalSchedules.length) {
      return <ScheduleCalendarShell schedules={data.findOriginalSchedules} />;
    }

    showInactiveSchedulesVar(true);
    return <NoSchedulesFoundMessage lineId={lineId} />;
  }

  return <></>;
};

export default ScheduleCalendar;
