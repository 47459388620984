import { compareAsc } from "date-fns";
import getAggregatedScheduleStatus from "../../../../shared/helpers/getAggregatedScheduleStatus/getAggregatedScheduleStatus";
import AggregatedScheduleStatus from "../../../../shared/helpers/getAggregatedScheduleStatus/types";
import { CalendarRow } from "../../types";

const sortCalendarRows = (rows: Array<CalendarRow>): Array<CalendarRow> =>
  rows
    .sort((a, b) => {
      return compareAsc(a.chunks[0].uiPeriod.start, b.chunks[0].uiPeriod.start);
    })
    .sort((a, b) => {
      const aIsInactive =
        getAggregatedScheduleStatus(a.chunks[0].schedules[0].displayedScheduleStatus) ===
        AggregatedScheduleStatus.INACTIVE;

      const bIsInactive =
        getAggregatedScheduleStatus(b.chunks[0].schedules[0].displayedScheduleStatus) ===
        AggregatedScheduleStatus.INACTIVE;

      if (aIsInactive && !bIsInactive) {
        return -1;
      }
      if (bIsInactive && !aIsInactive) {
        return 1;
      }
      return 0;
    });

export default sortCalendarRows;
