import React__default__default from 'react';
import ReactUtils from '../../packages/react-utils/react-utils.js';
import '../../packages/props-utils/prop-types.js';

/* eslint-disable react/prop-types */
var panelChildrenValidator = function () {
  return function (_a) {
    var children = _a.children;
    if (children == null) {
      return new Error('\'children\' is required for \'Panel\'.');
    }
    var panelChildrenArr = React__default__default.Children.toArray(children);
    var panelHeader = panelChildrenArr.filter(function (child) {
      var childName = child && ReactUtils.getElementName(child);
      return childName === 'PanelHeader';
    });
    if (panelHeader.length === 0) {
      return new Error('\'PanelHeader\' is required as a \'Panel\' child.');
    }
    var panelContent = panelChildrenArr.filter(function (child) {
      var childName = child && ReactUtils.getElementName(child);
      return childName === 'PanelContent';
    });
    if (panelContent.length === 0) {
      return new Error('\'PanelContent\' is required as a \'Panel\' child.');
    }
    return null;
  };
};

export { panelChildrenValidator };
