import { Spinner } from "@flixbus/honeycomb-react";
import {
  addNotification,
  DefaultInfoAlert,
  formatErrorMessage,
  NotificationType,
} from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { useIntl } from "react-intl";
import stripTypename from "../../shared/helpers/stripTypename/stripTypename";
import { Brand, PolicyInput, PolicyUnitsInput } from "../../shared/types/schema";
import PoliciesForm from "../policiesForm/PoliciesForm";
import * as css from "./PoliciesPanel.scss";
import { useFindBrandQuery, useUpdateBrandMutation } from "./api/operations.generated";
import PoliciesPanelTemplate from "./ui/policiesPanelTemplate/PoliciesPanelTemplate";

export type PoliciesPanelProps = {
  brandId: Brand["id"];
  onSubmit: () => void;
  onClose: () => void;
  readOnly: boolean;
};

const PoliciesPanel: React.FC<PoliciesPanelProps> = ({
  brandId,
  onSubmit,
  onClose,
  readOnly,
}) => {
  const { formatMessage } = useIntl();
  const { data, loading, error } = useFindBrandQuery({
    variables: { brandId },
  });

  const [updateBrand, { loading: updateBrandLoading }] = useUpdateBrandMutation({
    onCompleted: () => {
      addNotification({
        message: formatMessage({ id: "brand.update.notification.success" }),
      });
    },
    onError: (e) =>
      addNotification({
        message: `Brand update failed: ${formatErrorMessage(e.message)}`,
        type: NotificationType.Danger,
      }),
  });

  const handleUpdateBrand = (values: {
    policyUnits: PolicyUnitsInput;
    policies: Array<PolicyInput>;
  }) => {
    updateBrand({
      variables: {
        id: brandId,
        ...values,
      },
    });
  };

  if (loading) {
    return (
      <PoliciesPanelTemplate onClose={onClose}>
        <div className={css.wrapper}>
          <Spinner />
        </div>
      </PoliciesPanelTemplate>
    );
  }

  if (data?.findBrand) {
    const brand = data.findBrand;

    return (
      <PoliciesPanelTemplate brandName={brand.name} onClose={onClose}>
        <PoliciesForm
          defaultValues={{
            units: { ...stripTypename(brand.policyUnits) },
            policies: brand.policies.map((policy) => ({
              type: policy.type,
              policy: {
                ...stripTypename(policy.luggagePolicy),
                ...stripTypename(policy.passengerPolicy),
              },
              additionalInformation: policy.additionalInformation || undefined,
            })),
          }}
          onSubmit={(values) => {
            handleUpdateBrand(values);
            onSubmit();
          }}
          onClose={onClose}
          isSubmitLoading={updateBrandLoading}
          readOnly={readOnly}
        />
      </PoliciesPanelTemplate>
    );
  }

  if (error) {
    return (
      <PoliciesPanelTemplate onClose={onClose}>
        <DefaultInfoAlert
          message={formatMessage({ id: "brand.error.policiesNotFound" }, { brandId })}
        />
      </PoliciesPanelTemplate>
    );
  }

  return <></>;
};

export default PoliciesPanel;
