import { formatMessage } from "@flixbus-phx/marketplace-common";
import * as Yup from "yup";

export default () => {
  return Yup.object().shape({
    capacityDecreaseTimeFrameInHours: Yup.number()
      .min(1, formatMessage("error.validation.equalOrGreaterThanOne"))
      .max(24, formatMessage("error.validation.equalOrLessThanX", { value: "24" }))
      .typeError(formatMessage("error.validation.mustBeNumber")),
  });
};
