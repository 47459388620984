import { DisplayedScheduleStatus } from "../../../../shared/types/schema";
import { CalendarSchedule } from "../../types";

export default (schedules: CalendarSchedule[]): CalendarSchedule => {
  return (
    schedules.find((schedule) =>
      [DisplayedScheduleStatus.OnSale, DisplayedScheduleStatus.PartiallyOnSale].includes(
        schedule.displayedScheduleStatus
      )
    ) || schedules[0]
  );
};
