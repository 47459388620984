import { DefaultInfoAlert } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { Navigate } from "react-router-dom";
import buildPath from "../../routes/buildPath/buildPath";
import { useNewScheduleMutation } from "./api/operations.generated";
import { NewScheduleOnForm } from "./types";
import ScheduleForm from "./ui/scheduleForm/ScheduleForm";

type Props = {
  lineId: string;
};

const CreateSchedule: React.FC<Props> = ({ lineId }) => {
  const [addSchedule, { error: addError, loading: addLoading, data: addData }] =
    useNewScheduleMutation({
      onError: () => {},
    }); // https://github.com/apollographql/apollo-client/issues/7167

  const handleCreateSchedule = (values: NewScheduleOnForm) => {
    addSchedule({ variables: { ...values, lineId } });
  };

  if (!addError && !addLoading && addData?.newSchedule) {
    return <Navigate to={buildPath.showScheduleDetail(addData.newSchedule.id)} />;
  }

  return (
    <>
      {addError && <DefaultInfoAlert message={addError.message} />}

      <ScheduleForm
        lineId={lineId}
        onCreateSchedule={handleCreateSchedule}
        loading={addLoading}
      />
    </>
  );
};

export default CreateSchedule;
