import { Icon, IconProfile } from "@flixbus/honeycomb-icons-react";
import {
  Header,
  HeaderBrand,
  HeaderBrandProps,
  HeaderBurgerMenu,
  HeaderNavigation,
  HeaderUserWidget,
  HeaderWidgets,
  NavItem,
} from "@flixbus/honeycomb-react";
import * as React from "react";
import logoFlix from "../../../assets/logo/flix_white.svg";
import logoMarketplace from "../../../assets/logo/flixmarketplace_white.svg";
import { Feature, hasUserPermission, useUserName } from "../../auth";
import { commonPathnames } from "../../helper/pathnames/pathnames";
import { getThemeSetting } from "../../helper/themeToggler/themeToggler";
import HeaderStagingBackground from "../headerStagingBackground/HeaderStagingBackground";
import LANGUAGE_SWITCHER_PORTAL_ID from "../languageSwitcher/LanguageSwitcherPortalId";
import * as css from "./Navigation.scss";
import getNavigationItems from "./helpers/getNavigationItems";
import App from "./types";
import getWidgetNavigation from "./widgetNavigation/getWidgetNavigation";

type Props = {
  app: App;
  widget?: JSX.Element;
  isUsedInternal: boolean;
  RouterLink?: HeaderBrandProps["RouterLink"];
};

const Navigation: React.FC<Props> = ({ app, widget, isUsedInternal, RouterLink }) => {
  const userName = useUserName();
  const navigation = getNavigationItems(app);

  return (
    <>
      <Header
        brand={
          <HeaderBrand
            src={isUsedInternal ? logoMarketplace : logoFlix}
            alt={isUsedInternal ? "FlixBus Marketplace" : "Flix"}
            RouterLink={RouterLink}
            href={commonPathnames.userHome}
            height="20px"
            width="auto"
            theme={getThemeSetting()}
          />
        }
        navigation={
          hasUserPermission(Feature.USER_HAS_VIEW_ACCESS_TO_ALL_PAGES) &&
          isUsedInternal ? (
            <HeaderNavigation data-id="desktop" aria-label="Main">
              {navigation}
            </HeaderNavigation>
          ) : (
            <></>
          )
        }
        burgerMenu={
          hasUserPermission(Feature.USER_HAS_VIEW_ACCESS_TO_ALL_PAGES) &&
          isUsedInternal ? (
            <HeaderBurgerMenu
              openPanelLabel="Open main menu"
              closePanelLabel="Close main menu"
              panelId="burger-panel"
            >
              <HeaderNavigation data-id="mobile" aria-label="Main">
                {[
                  ...navigation,
                  <NavItem
                    data-id="widget-mobile-menu"
                    key="userMenu"
                    subMenu={getWidgetNavigation()}
                    extraClasses={css.widgetMobileMenu}
                  >
                    <Icon InlineIcon={IconProfile} size={5} />
                    &nbsp;{userName}
                  </NavItem>,
                ]}
              </HeaderNavigation>
            </HeaderBurgerMenu>
          ) : (
            <></>
          )
        }
        widgets={
          <HeaderWidgets>
            {isUsedInternal ? (
              <HeaderUserWidget subMenu={getWidgetNavigation()}>
                {/* This is a prank I played on Julia. If you dont think its funny anymore remove it */}
                {userName === "Julia Jormakka"
                  ? "Julia Eva Aili Maria Alvariina Jormakka"
                  : userName}
              </HeaderUserWidget>
            ) : (
              widget
            )}
          </HeaderWidgets>
        }
        extraClasses={css.header}
        fixed
      />

      <HeaderStagingBackground />

      {/* portal into which the language switcher renders into */}
      <div id={LANGUAGE_SWITCHER_PORTAL_ID} />
    </>
  );
};

export default Navigation;
