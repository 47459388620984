enum AggregatedScheduleStatus {
  ON_SALE = "ON_SALE",
  PROCESSING = "PROCESSING",
  HIDDEN = "HIDDEN",
  IN_REVIEW = "IN_REVIEW",
  DRAFT = "DRAFT",
  INACTIVE = "INACTIVE",
}

export default AggregatedScheduleStatus;
