import { DefaultInfoAlert, InfoAlert } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { useFindStationsInScheduleAndCityQuery } from "../../api/operations.generated";
import CityStationsAccordion from "../../ui/cityStationsAccordion/CityStationsAccordion";
import StationDetailLoading from "../../ui/stationDetailLoading/StationDetailLoading";

export const STATION_NOT_ASSIGNED_TO_A_CITY_MESSAGE =
  "The selected station is not assigned to a city. Please assign this station to a city in order to see all stations of a city.";

type Props = {
  scheduleId: string;
  stationId: string;
  onPanelClose: () => void;
};

const CityStationsTab: React.FC<Props> = ({ scheduleId, stationId, onPanelClose }) => {
  const { data, loading, error } = useFindStationsInScheduleAndCityQuery({
    variables: { scheduleId, stationId },
  });

  if (loading) {
    return <StationDetailLoading />;
  }

  if (data?.findStation.city?.stations && data.findSchedule) {
    const scheduleStationIds = data.findSchedule.stations.map((station) => station.id);

    return (
      <CityStationsAccordion
        scheduleId={scheduleId}
        originalStationId={data.findStation.id}
        originalStationName={data.findStation.name}
        stations={data.findStation.city.stations}
        scheduleStationIds={scheduleStationIds}
        scheduleReadOnly={data.findSchedule.readOnly}
        onPanelClose={onPanelClose}
      />
    );
  }

  if (data?.findStation.city === null && !loading) {
    return <InfoAlert type="warning">{STATION_NOT_ASSIGNED_TO_A_CITY_MESSAGE}</InfoAlert>;
  }

  if (error) {
    return (
      <DefaultInfoAlert
        message={`Could not load city data for station with id ${stationId}`}
      />
    );
  }

  return <></>;
};

export default CityStationsTab;
