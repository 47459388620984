import { useReactiveVar } from "@apollo/client";
import {
  Icon,
  IconArrowBigTopRight,
  IconArrowDown,
  IconArrowUp,
} from "@flixbus/honeycomb-icons-react";
import { Button } from "@flixbus/honeycomb-react";
import React from "react";
import { FormattedMessage } from "react-intl";
import Portal from "../../../../shared/components/portal/Portal";
import showInactiveSchedulesVar from "../../../../state/showInactiveSchedulesVar/showInactiveSchedulesVar";
import { expandButtonRowHeight } from "../../helper/sizes/sizes";
import * as css from "./CalendarExpandButtonRow.scss";

export type CalendarExpandButtonRowProps = {
  rowStart: number;
  expanded: boolean;
  onClick: () => void;
};

const CalendarExpandButtonRow: React.FC<CalendarExpandButtonRowProps> = ({
  rowStart,
  expanded,
  onClick,
}) => {
  const showInactive = useReactiveVar(showInactiveSchedulesVar);

  return (
    <Portal portalId="calendar-expand-button-portal">
      <div
        className={css.expandButtonRow}
        style={{
          height: `${expandButtonRowHeight}px`,
          top: `${rowStart}px`,
        }}
      >
        <Button extraClasses={css.button} size="sm" onClick={onClick}>
          <Icon
            extraClasses={css.iconArrowBigTopRight}
            InlineIcon={IconArrowBigTopRight}
            size={2}
          />
          {expanded ? (
            <FormattedMessage
              id={
                showInactive
                  ? "scheduleCalendar.schedule.expanded.includingInactive"
                  : "scheduleCalendar.schedule.expanded"
              }
            />
          ) : (
            <FormattedMessage
              id={
                showInactive
                  ? "scheduleCalendar.schedule.collapsed.includingInactive"
                  : "scheduleCalendar.schedule.collapsed"
              }
            />
          )}
          <Icon InlineIcon={expanded ? IconArrowUp : IconArrowDown} />
        </Button>
      </div>
    </Portal>
  );
};

export default CalendarExpandButtonRow;

export const MemoizedCalendarExpandButtonRow = React.memo(
  CalendarExpandButtonRow, // prevents constant rerender when scrolling horizontally
  (prevProps, nextProps) => {
    return (
      prevProps.expanded === nextProps.expanded &&
      prevProps.rowStart === nextProps.rowStart
    );
  }
);
