@use "variables" as *;
@use "../../helper/styles/styles";

.expandButtonRow {
  position: absolute;
  left: 0;
  width: 100%;
  border-bottom: $border;
}

.button {
  transform: translateX(5px);
  z-index: 1;
}

.iconArrowBigTopRight {
  fill: $icon-primary-color !important;
}
