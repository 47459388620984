import React__default__default from 'react';

/** Generate a unique id prefixed for components aria attributes.
 * Will use React 18 `useId` if available, otherwise generate a randomized number based on
 * the timestamp.
 * Automatic id generation can be overwritten by consumers that provide a component id.
 * @param prefix The id prefix
 * @param suffix Optionally replace the automatically generated id
 * @returns `{prefix}-{random-id|manual-suffix}`
 */
function useComponentId(prefix, suffix) {
  if (process.env.NODE_ENV === 'test') {
    return "".concat(prefix, "-").concat(suffix || 'useComponentId-TEST');
  }
  // If consumer uses React < 18 they don't have `useId`. So we want to explicitly walk around TS.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  var reactUseId = React__default__default.useId;
  // if useId exists, use it, otherwise generate a timestamp and randomize it
  var componentId = typeof reactUseId === 'function' ? reactUseId() : Math.floor(Date.now() * Math.random());
  var suffixVal = React__default__default.useState(suffix !== null && suffix !== void 0 ? suffix : componentId)[0];
  return "".concat(prefix, "-").concat(suffixVal);
}

export { useComponentId as default };
