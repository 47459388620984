import themeConfig from '../../themes.conf.js';
import { HCR_VERSION_SUFFIX } from '../../packages/injecss/injecss.js';

var themeClassName = function (themeName) {
  return "".concat(themeConfig.honeycombThemePrefix).concat(themeName, "-").concat(HCR_VERSION_SUFFIX);
};
/**
 * Here we basically simulate the output of "sass-unique-classes loader" existing configuration.
 * The output is a CSS string with all the design tokens and theme flix styles.
 * If "applyToRoot" is set resulting selectors will apply tokens and base styles to root element,
 * this is specifically useful for "scaled" themes such as TVM ones,
 * as we need to control the base font size of the root element.
 * @param themes
 * @param applyToRoot
 */
var generateStylesObject = function (themes, applyToRoot) {
  if (applyToRoot === void 0) {
    applyToRoot = false;
  }
  var themeNames = Object.keys(themes);
  var cssString = themeNames.map(function (themeName) {
    var themeTokens = Object.keys(themes[themeName]);
    var themeTokenValues = Object.values(themes[themeName]);
    // @todo find a better way to reduce the style duplication
    return "\n.".concat(themeClassName(themeName)).concat(applyToRoot ? ':root' : '').concat(themeName === 'flix' && !applyToRoot ? ', :root' : '', " {\n  ").concat(themeTokens.map(function (token, tokenIndex) {
      return "--hcr-".concat(token, ": ").concat(themeTokenValues[tokenIndex], ";");
    }).join(' '), "\n}\n.").concat(themeClassName(themeName)).concat(applyToRoot ? ':root' : '').concat(themeName === 'flix' && !applyToRoot ? ', :root' : '', " {\n  color: var(--hcr-content-primary-color);\n  font-family: var(--hcr-font-family-primary);\n  font-size: var(--hcr-font-size-base);\n  font-weight: var(--hcr-font-weight-normal);\n  line-height: var(--hcr-line-height-primary);\n  color-scheme: var(--hcr-color-scheme);\n  @media only screen and (-webkit-min-device-pixel-ratio: 2) and (pointer: coarse) {\n    font: -apple-system-body;\n    font-family: var(--hcr-font-family-primary);\n  }\n}");
  });
  return {
    raw: cssString.join('\n')
  };
};

export { generateStylesObject, themeClassName };
