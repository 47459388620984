import { MsalProvider } from "@azure/msal-react";
import * as React from "react";
import AuthTemplate from "../AuthTemplate/AuthTemplate";
import { msalInstance } from "../config/authConfig";

const AuthProvider: React.FC = ({ children }) => {
  return (
    <MsalProvider instance={msalInstance}>
      <AuthTemplate>{children}</AuthTemplate>
    </MsalProvider>
  );
};

export default AuthProvider;
