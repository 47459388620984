import { Icon, IconInfo } from "@flixbus/honeycomb-icons-react";
import { Tooltip } from "@flixbus/honeycomb-react";
import * as React from "react";
import FormikTrueFalseSelect from "../../../../shared/components/formikTrueFalseSelect/FormikTrueFalseSelect";
import * as css from "./InterconnectionPricingRuleSelect.scss";

type Props = {
  name: string;
  header: string;
  description: string;
  tooltipContent: string;
  readOnly?: boolean;
};

const InterconnectionPricingRuleSelect: React.FC<Props> = ({
  name,
  header,
  description,
  tooltipContent,
  readOnly,
}) => {
  return (
    <div className={css.wrapper}>
      <div data-id="laber-wrapper" className={css.labelWrapper}>
        <p className={css.labelHeader}>
          {header}
          <Tooltip
            id={`${name}-tooltip`}
            extraClasses={css.labelTooltip}
            content={
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: tooltipContent,
                }}
              />
            }
            openOnHover
            size="medium"
          >
            <Icon InlineIcon={IconInfo} size={3} extraClasses={css.toolTipIcon} />
          </Tooltip>
        </p>
        <p className={css.labelDescription}>{description}</p>
      </div>
      <FormikTrueFalseSelect
        name={name}
        trueLabel="Allowed"
        falseLabel="Disabled"
        readOnly={readOnly}
        disableClick
      />
    </div>
  );
};

export default InterconnectionPricingRuleSelect;
