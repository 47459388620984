import { DisplayedScheduleStatus } from "../../../../shared/types/schema";
import { InputSchedule } from "../../types";

export default (schedule: Pick<InputSchedule, "displayedScheduleStatus">): boolean =>
  [
    DisplayedScheduleStatus.OnSale,
    DisplayedScheduleStatus.PartiallyOnSale,
    DisplayedScheduleStatus.OutOfSale,
    DisplayedScheduleStatus.Archived,
  ].includes(schedule.displayedScheduleStatus);
