import { Schedule } from "../../../../shared/types/schema";
import { Calendar, CalendarSchedule } from "../../types";

const getScheduleOfCalendarById = (
  calendar: Calendar,
  id: Schedule["id"]
): CalendarSchedule | undefined => {
  let foundSchedule;

  calendar.rows.forEach((row) => {
    row.chunks.forEach((chunk) => {
      chunk.schedules.forEach((schedule) => {
        if (schedule.id === id) {
          foundSchedule = schedule;
        }
      });

      if (row.hasInvisibleConceptChanges) {
        row.invisibleConceptChanges.forEach(({ schedule }) => {
          if (schedule.id === id) {
            foundSchedule = schedule;
          }
        });
      }
    });
  });

  return foundSchedule;
};

export default getScheduleOfCalendarById;
