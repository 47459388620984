import getAggregatedScheduleStatus from "../../../../shared/helpers/getAggregatedScheduleStatus/getAggregatedScheduleStatus";
import AggregatedScheduleStatus from "../../../../shared/helpers/getAggregatedScheduleStatus/types";
import { InputSchedule } from "../../types";

export default (
  schedule: Pick<InputSchedule, "displayedScheduleStatus">,
  isOriginalScheduleOnSale: boolean,
  withInactive: boolean
): boolean => {
  const inactiveConceptChangeStatuses = [
    AggregatedScheduleStatus.DRAFT,
    AggregatedScheduleStatus.IN_REVIEW,
    AggregatedScheduleStatus.PROCESSING,
    AggregatedScheduleStatus.HIDDEN,
  ];

  if (!isOriginalScheduleOnSale) {
    inactiveConceptChangeStatuses.push(AggregatedScheduleStatus.ON_SALE);
  }

  if (withInactive) {
    inactiveConceptChangeStatuses.push(AggregatedScheduleStatus.INACTIVE);
  }

  const aggregatedScheduleStatus = getAggregatedScheduleStatus(
    schedule.displayedScheduleStatus
  );

  return inactiveConceptChangeStatuses.includes(aggregatedScheduleStatus);
};
