import { CalendarRow } from "../../types";

export const stickyHeaderHeight = 35;
export const headerHeight = 40;
export const contentCellHeight = 54;
export const expandButtonRowHeight = 35;
export const contentCellHeightWithButton = contentCellHeight + expandButtonRowHeight;
export const contentCellWidthDaily = 60;
export const contentCellWidthMonthly = 98;
export const fullContainerWidth = 1176;

export const calculateRowHeight = (calendarRow: CalendarRow, expanded: boolean) => {
  if (!calendarRow.hasInvisibleConceptChanges) return contentCellHeight;

  if (expanded) {
    return (
      contentCellHeightWithButton +
      calendarRow.invisibleConceptChanges.length * contentCellHeight
    );
  }

  return contentCellHeightWithButton;
};
