// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import * as Types from "../../../shared/types/schema";

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
const defaultOptions = {} as const;
export type FindScheduleForFooterQueryVariables = Types.Exact<{
  scheduleId: Types.Scalars["ID"]["input"];
}>;

export type FindScheduleForFooterQuery = {
  __typename?: "Query";
  findSchedule: {
    __typename?: "Schedule";
    id: string;
    name: string;
    readOnly: boolean;
    status: Types.ScheduleStatus;
    lastPublishFailed: boolean;
    wasCreatedViaApi: boolean;
    line: {
      __typename?: "Line";
      id: string;
      networkCode: string;
      partner: {
        __typename?: "Partner";
        id: string;
        businessDevelopers: Array<{ __typename?: "BusinessDeveloper"; email: string }>;
      };
    };
  };
};

export type ValidateScheduleQueryVariables = Types.Exact<{
  scheduleId: Types.Scalars["ID"]["input"];
}>;

export type ValidateScheduleQuery = {
  __typename?: "Query";
  validateSchedule: {
    __typename?: "ValidationResult";
    isValid?: boolean | null;
    validationInfos: Array<{
      __typename?: "ValidationInfo";
      valid: boolean;
      infoText: string;
      failedValidationInfos: Array<string>;
    }>;
  };
};

export type UpdateScheduleStatusMutationVariables = Types.Exact<{
  scheduleId: Types.Scalars["ID"]["input"];
  status: Types.ScheduleStatus;
}>;

export type UpdateScheduleStatusMutation = {
  __typename?: "Mutation";
  updateScheduleStatus: {
    __typename?: "Schedule";
    id: string;
    name: string;
    readOnly: boolean;
    status: Types.ScheduleStatus;
    trips: Array<{ __typename?: "Trip"; id: string; readOnly: boolean }>;
  };
};

export type PublishScheduleMutationVariables = Types.Exact<{
  scheduleId: Types.Scalars["ID"]["input"];
}>;

export type PublishScheduleMutation = {
  __typename?: "Mutation";
  publishSchedule: {
    __typename?: "Schedule";
    id: string;
    name: string;
    readOnly: boolean;
    status: Types.ScheduleStatus;
    lastPublishFailed: boolean;
  };
};

export type PublishPricesMutationVariables = Types.Exact<{
  scheduleId: Types.Scalars["ID"]["input"];
}>;

export type PublishPricesMutation = {
  __typename?: "Mutation";
  publishPrices: {
    __typename?: "Schedule";
    id: string;
    name: string;
    readOnly: boolean;
    status: Types.ScheduleStatus;
    lastPublishFailed: boolean;
  };
};

export const FindScheduleForFooterDocument = gql`
  query FindScheduleForFooter($scheduleId: ID!) {
    findSchedule(id: $scheduleId) {
      id
      name
      readOnly
      status
      lastPublishFailed
      wasCreatedViaApi
      line {
        id
        networkCode
        partner {
          id
          businessDevelopers {
            email
          }
        }
      }
    }
  }
`;

/**
 * __useFindScheduleForFooterQuery__
 *
 * To run a query within a React component, call `useFindScheduleForFooterQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindScheduleForFooterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindScheduleForFooterQuery({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *   },
 * });
 */
export function useFindScheduleForFooterQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FindScheduleForFooterQuery,
    FindScheduleForFooterQueryVariables
  > &
    (
      | { variables: FindScheduleForFooterQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FindScheduleForFooterQuery,
    FindScheduleForFooterQueryVariables
  >(FindScheduleForFooterDocument, options);
}
export function useFindScheduleForFooterLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindScheduleForFooterQuery,
    FindScheduleForFooterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FindScheduleForFooterQuery,
    FindScheduleForFooterQueryVariables
  >(FindScheduleForFooterDocument, options);
}
export function useFindScheduleForFooterSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        FindScheduleForFooterQuery,
        FindScheduleForFooterQueryVariables
      >
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    FindScheduleForFooterQuery,
    FindScheduleForFooterQueryVariables
  >(FindScheduleForFooterDocument, options);
}
export type FindScheduleForFooterQueryHookResult = ReturnType<
  typeof useFindScheduleForFooterQuery
>;
export type FindScheduleForFooterLazyQueryHookResult = ReturnType<
  typeof useFindScheduleForFooterLazyQuery
>;
export type FindScheduleForFooterSuspenseQueryHookResult = ReturnType<
  typeof useFindScheduleForFooterSuspenseQuery
>;
export const ValidateScheduleDocument = gql`
  query validateSchedule($scheduleId: ID!) {
    validateSchedule(scheduleId: $scheduleId) {
      isValid
      validationInfos {
        valid
        infoText
        failedValidationInfos
      }
    }
  }
`;

/**
 * __useValidateScheduleQuery__
 *
 * To run a query within a React component, call `useValidateScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateScheduleQuery({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *   },
 * });
 */
export function useValidateScheduleQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ValidateScheduleQuery,
    ValidateScheduleQueryVariables
  > &
    ({ variables: ValidateScheduleQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ValidateScheduleQuery, ValidateScheduleQueryVariables>(
    ValidateScheduleDocument,
    options
  );
}
export function useValidateScheduleLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ValidateScheduleQuery,
    ValidateScheduleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ValidateScheduleQuery,
    ValidateScheduleQueryVariables
  >(ValidateScheduleDocument, options);
}
export function useValidateScheduleSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        ValidateScheduleQuery,
        ValidateScheduleQueryVariables
      >
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    ValidateScheduleQuery,
    ValidateScheduleQueryVariables
  >(ValidateScheduleDocument, options);
}
export type ValidateScheduleQueryHookResult = ReturnType<typeof useValidateScheduleQuery>;
export type ValidateScheduleLazyQueryHookResult = ReturnType<
  typeof useValidateScheduleLazyQuery
>;
export type ValidateScheduleSuspenseQueryHookResult = ReturnType<
  typeof useValidateScheduleSuspenseQuery
>;
export const UpdateScheduleStatusDocument = gql`
  mutation updateScheduleStatus($scheduleId: ID!, $status: ScheduleStatus!) {
    updateScheduleStatus(id: $scheduleId, status: $status) {
      id
      name
      readOnly
      status
      trips {
        id
        readOnly
      }
    }
  }
`;

/**
 * __useUpdateScheduleStatusMutation__
 *
 * To run a mutation, you first call `useUpdateScheduleStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScheduleStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScheduleStatusMutation, { data, loading, error }] = useUpdateScheduleStatusMutation({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateScheduleStatusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateScheduleStatusMutation,
    UpdateScheduleStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateScheduleStatusMutation,
    UpdateScheduleStatusMutationVariables
  >(UpdateScheduleStatusDocument, options);
}
export type UpdateScheduleStatusMutationHookResult = ReturnType<
  typeof useUpdateScheduleStatusMutation
>;
export const PublishScheduleDocument = gql`
  mutation publishSchedule($scheduleId: ID!) {
    publishSchedule(id: $scheduleId) {
      id
      name
      readOnly
      status
      lastPublishFailed
    }
  }
`;

/**
 * __usePublishScheduleMutation__
 *
 * To run a mutation, you first call `usePublishScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishScheduleMutation, { data, loading, error }] = usePublishScheduleMutation({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *   },
 * });
 */
export function usePublishScheduleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    PublishScheduleMutation,
    PublishScheduleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    PublishScheduleMutation,
    PublishScheduleMutationVariables
  >(PublishScheduleDocument, options);
}
export type PublishScheduleMutationHookResult = ReturnType<
  typeof usePublishScheduleMutation
>;
export const PublishPricesDocument = gql`
  mutation publishPrices($scheduleId: ID!) {
    publishPrices(scheduleId: $scheduleId) {
      id
      name
      readOnly
      status
      lastPublishFailed
    }
  }
`;

/**
 * __usePublishPricesMutation__
 *
 * To run a mutation, you first call `usePublishPricesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishPricesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishPricesMutation, { data, loading, error }] = usePublishPricesMutation({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *   },
 * });
 */
export function usePublishPricesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    PublishPricesMutation,
    PublishPricesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    PublishPricesMutation,
    PublishPricesMutationVariables
  >(PublishPricesDocument, options);
}
export type PublishPricesMutationHookResult = ReturnType<typeof usePublishPricesMutation>;
