@use "variables" as *;

.wrapper {
  position: absolute;
  top: 13px;
  right: 4px;
}

.button {
  min-width: unset;
  min-height: unset;
  width: 28px;
  height: 24px;
  padding: 0;
}

.tooltipList {
  position: relative;
  padding: 0;
  margin: 0;

  .item {
    list-style: none;
    color: $content-secondary-color;
    padding: $spacing-half $spacing-4;
    cursor: pointer;
    font-weight: $font-weight-semibold;

    &:not(:last-child) {
      margin-bottom: $spacing-1;
    }

    &:hover {
      background: $hover-layer-color;
    }
  }
}
