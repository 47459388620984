var HoneyBem = /** @class */function () {
  function HoneyBem(blockName, module, prefix) {
    if (prefix === void 0) {
      prefix = "hcr-";
    }
    // eslint-disable-next-line class-methods-use-this
    this.convertModifiersToClassNames = function mapModifiersObj(mods, base) {
      return Object.keys(mods).reduce(function (a, c) {
        return mods[c] ? "".concat(a, " ").concat(base, "--").concat(c) : a;
      }, '').trim();
    };
    this.block = "".concat(prefix).concat(blockName);
    this.module = module;
  }
  /**
   * @deprecated use constructor instead.
   * @param blockName
   * @param module
   * @param prefix
   * @returns
   */
  HoneyBem.init = function (blockName, module, prefix) {
    return new HoneyBem(blockName, module, prefix);
  };
  HoneyBem.prototype.b = function (mods, mixes) {
    var classArr = [this.block];
    // populating array with mods and mixes class strings
    // eslint-disable-next-line no-unused-expressions
    mods && classArr.push(this.m(mods, this.block));
    // eslint-disable-next-line no-unused-expressions
    mixes && classArr.push(this.mix(mixes));
    // concatenating after removing empty strings
    // and passing to this convert to map against css mapping, if any
    return this.convert(classArr.filter(function (current) {
      return current;
    }).join(' '));
  };
  HoneyBem.prototype.e = function (children, mods, mixes) {
    var e = typeof children === 'string' ? "".concat(this.block, "__").concat(children) : "".concat(this.block).concat(children.reduce(function (acc, cur) {
      return "__".concat(acc, "__").concat(cur);
    }));
    var classArr = [e];
    // populating array with mods and mixes class strings
    // eslint-disable-next-line no-unused-expressions
    mods && classArr.push(this.m(mods, e));
    // eslint-disable-next-line no-unused-expressions
    mixes && classArr.push(this.mix(mixes));
    // concatenating after removing empty strings
    // and passing to this convert to map against css mapping, if any
    return this.convert(classArr.filter(function (current) {
      return current;
    }).join(' '));
  };
  HoneyBem.prototype.m = function (mods, baseOverride) {
    var _this = this;
    var base = baseOverride || this.block;
    if (typeof mods === 'string') {
      // if modifier is a string chain it with base
      return "".concat(base, "--").concat(mods).trim();
    }
    if (mods instanceof Object && !Array.isArray(mods)) {
      // if object of modifiers given pass them all to the mapping/chaining function
      return this.convertModifiersToClassNames(mods, base).trim();
    }
    if (Array.isArray(mods)) {
      // remove null, false and empty array
      var cleanMods = mods.filter(function (mod) {
        return mod && (typeof mod === 'string' || mod instanceof Object);
      });
      // map mod (either string or Object)
      var m = cleanMods.map(function (mod) {
        if (mod instanceof Object) {
          return _this.convertModifiersToClassNames(mod, base);
        }
        return "".concat(base, "--").concat(mod);
      }).join(' '); // join modifier sets for every array item into one modifier string
      return m.trim();
    }
    return '';
  };
  HoneyBem.prototype.mix = function (mix) {
    var _this = this;
    if (typeof mix === 'string') {
      // if string provided, pass it through
      return mix.trim();
    }
    if (Array.isArray(mix)) {
      // if array given, flatten all objects inside and concatenate resulting classes
      return mix.map(function (mixItem) {
        if (mixItem instanceof Object) {
          return _this.convertMixesToClassNames(mixItem);
        }
        return mixItem;
      }).join(' ').trim();
    }
    if (mix instanceof Object) {
      // in case we have object, flatten keys depending of the value
      return this.convertMixesToClassNames(mix).trim();
    }
    return '';
  };
  HoneyBem.prototype.convert = function (selectors) {
    var _this = this;
    if (this.module) {
      // eslint-disable-next-line arrow-body-style
      return selectors.padEnd(1).split(' ').map(function (a) {
        var _a;
        return ((_a = _this.module) === null || _a === void 0 ? void 0 : _a.map[a]) ? _this.module.map[a] : a;
      }).join(' ');
    }
    return selectors;
  };
  // eslint-disable-next-line class-methods-use-this
  HoneyBem.prototype.convertMixesToClassNames = function (mixObj) {
    return Object.keys(mixObj).filter(function (current) {
      return mixObj[current];
    }).join(' ');
  };
  return HoneyBem;
}();

export { HoneyBem as default };
