import { Heading } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import InterconnectionPricingRuleSelect from "../interconnectionPricingRuleSelect/InterconnectionPricingRuleSelect";
import * as css from "./InterconnectionPricingRulesInput.scss";

type Props = {
  readOnly: boolean;
};

// No translations are required for this component as it is only visible to Admin/OPs and BDs (Read-only)
const InterconnectionPricingRulesInput: React.FC<Props> = ({ readOnly }) => {
  return (
    <>
      <Heading size={4} extraClasses={css.heading}>
        <FormattedMessage id="partnerForm.finance.interconnectionPricing.headline" />
      </Heading>
      <InterconnectionPricingRuleSelect
        name="totalInterconnectionPricing"
        header="1. Total Interconnection Pricing"
        description="It is the most powerful setting and it affects the total price of the interconnection where this partner would take part in."
        tooltipContent="<b>Allow:</b><br/>Flix adjusts the total trip price, which may be higher or lower than the sum of the individual leg prices.<br/><br/><b>Disable:</b><br/>Whenever this partner would interconnect, the total price of the trip would be calculated as the sum of all legs involved, hence affecting the pricing logic of all other partners that would interconnect with this one."
        readOnly={readOnly}
      />
      <InterconnectionPricingRuleSelect
        name="priceChangeAllowed"
        header="2. Price Overwrite"
        description="Unlike the logic above, this setting applies only to the specific partner’s price in an interconnection."
        tooltipContent="<b>Allow:</b><br/><ul><li>Flix will overwrite the standalone price and apply its strategic logic whenever this partner interconnects.</li><li>Revenue distribution: per distance</li></ul><br/><b>Disable:</b><br/><ul><li>Flix will use the standalone price whenever this partner interconnects.</li><li>Revenue distribution: per leg</li></ul>"
        readOnly={readOnly}
      />
      <InterconnectionPricingRuleSelect
        name="externalInterconnectionPricingAllowed"
        header="3. External Interconnection Pricing (For TDS Carriers Only)"
        description="This rule decides whether the source of truth for pricing would be Team Phoenix or any other external party (TDS currently)."
        tooltipContent="<b>Allow:</b><br/>For TDS-to-TDS interconnections, pricing will directly come from TDS (via Neon).<br/><br/><b>Disable:</b><br/>Pricing will come from Team Phoenix."
        readOnly={readOnly}
      />
    </>
  );
};

export default InterconnectionPricingRulesInput;
