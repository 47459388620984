import { interval } from "date-fns";
import { DisplayedScheduleStatus } from "../../../../shared/types/schema";
import { CalendarSchedule, InputSchedule, InvisibleConceptChange } from "../../types";
import compareConceptChanges from "../compareConceptChanges/compareConceptChanges";
import isConceptChangeInvisible from "../isConceptChangeInvisible/isConceptChangeInvisible";
import isOrWasScheduleOnSale from "../isOrWasScheduleOnSale/isOrWasScheduleOnSale";

export default (
  originalSchedule: InputSchedule,
  conceptChanges: Array<CalendarSchedule>,
  withInactive: boolean
): Array<InvisibleConceptChange> =>
  conceptChanges
    .filter(
      (cc) =>
        cc.displayedScheduleStatus !== DisplayedScheduleStatus.OutOfSale &&
        isConceptChangeInvisible(
          cc,
          isOrWasScheduleOnSale(originalSchedule),
          withInactive
        )
    )
    .map((cc) => ({
      uiPeriod: interval(cc.period!!.start, cc.period!!.end),
      schedule: cc,
    }))
    .sort(compareConceptChanges);
