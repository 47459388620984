import { SelectGroup } from "@flixbus/honeycomb-react";
import * as React from "react";
import { useIntl } from "react-intl";
import { CalendarIntervalType } from "../../types";

export type ZoomControlProps = {
  onIntervalTypeChange: (selectedIntervalType: CalendarIntervalType) => void;
  selectedIntervalType: CalendarIntervalType;
};

const ZoomControl: React.FC<ZoomControlProps> = ({
  onIntervalTypeChange,
  selectedIntervalType,
}) => {
  const { formatMessage } = useIntl();
  const options = [
    {
      id: "month",
      value: "month",
      name: "period",
      label: formatMessage({ id: "general.month" }),
      checked: selectedIntervalType === CalendarIntervalType.DAILY,
      onChange: () => {
        onIntervalTypeChange(CalendarIntervalType.DAILY);
      },
    },
    {
      id: "year",
      value: "year",
      name: "period",
      label: formatMessage({ id: "general.year" }),
      checked: selectedIntervalType === CalendarIntervalType.MONTHLY,
      onChange: () => {
        onIntervalTypeChange(CalendarIntervalType.MONTHLY);
      },
    },
  ];

  return (
    <div>
      <SelectGroup aria-label="period" options={options} />
    </div>
  );
};

export default ZoomControl;
