import { Button } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { commonPathnames } from "../../../../../helper";

type Props = {
  primary: Boolean;
};

const BackToMainPageButton: React.FC<Props> = ({ primary }) => (
  <Button
    data-id="back-button"
    appearance={primary ? "primary" : undefined}
    href={commonPathnames.userHome}
    Elem="a"
  >
    <FormattedMessage id="home.button.text" />
  </Button>
);

export default BackToMainPageButton;
