import { Skeleton } from "@flixbus/honeycomb-react";
import {
  addNotification,
  DefaultInfoAlert,
  formatErrorMessage,
  NotificationType,
} from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { useIntl } from "react-intl";
import {
  ScheduleDetailFragment,
  UpdateScheduleMutationVariables,
  useScheduleDetailQuery,
  useUpdateScheduleMutation,
} from "../../api/operations.generated";
import ConceptChangeInfoBox from "../../ui/conceptChangeInfoBox/ConceptChangeInfoBox";
import ReadOnlyHint from "../../ui/readOnlyHint/ReadOnlyHint";
import ScheduleDetailBox from "../../ui/scheduleDetailBox/ScheduleDetailBox";

type Props = {
  scheduleId: string;
};

const ScheduleInfo: React.FC<Props> = ({ scheduleId }) => {
  const { formatMessage } = useIntl();
  const {
    data: queryScheduleData,
    loading: queryLoading,
    error: queryError,
  } = useScheduleDetailQuery({
    variables: { scheduleId },
  });

  const [updateSchedule] = useUpdateScheduleMutation({
    onError: (error) =>
      addNotification({
        message: `Schedule update failed: ${formatErrorMessage(error.message)}`,
        type: NotificationType.Danger,
      }),
    onCompleted: () =>
      addNotification({
        message: formatMessage({ id: "scheduleDetail.submit.notification.success" }),
      }),
  });

  const handleScheduleChange = (
    value:
      | NonNullable<ScheduleDetailFragment["circulation"]>["uuid"]
      | ScheduleDetailFragment["period"]
      | ScheduleDetailFragment["nonOperatingPeriods"]
      | null,
    key: keyof UpdateScheduleMutationVariables
  ) => {
    const variables: UpdateScheduleMutationVariables = {
      id: scheduleId,
      [key]: value,
    };
    updateSchedule({ variables });
  };

  if (queryLoading) {
    return <Skeleton width="sm" height="md" flushBottom={false} />;
  }

  if (queryScheduleData?.findSchedule) {
    const schedule = queryScheduleData.findSchedule;

    return (
      <>
        <ReadOnlyHint readOnly={schedule.readOnly} />
        <ConceptChangeInfoBox
          conceptChangeOf={schedule.conceptChangeOf}
          conceptChanges={schedule.conceptChanges}
        />

        <ScheduleDetailBox
          schedule={schedule}
          onHandleScheduleChange={handleScheduleChange}
        />
      </>
    );
  }

  if (queryError) {
    return (
      <DefaultInfoAlert
        message={formatMessage({ id: "schedule.error.scheduleNotFound" }, { scheduleId })}
      />
    );
  }

  return <></>;
};

export default ScheduleInfo;
