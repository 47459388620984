import { Feature, hasUserPermission } from "@flixbus-phx/marketplace-common";
import { PartnerEditableFields } from "../types";

const getEditablePartnerFields = (
  partnerIsReadOnly: boolean,
  isCreation: boolean
): PartnerEditableFields => {
  return {
    accountInput: {
      name: partnerIsReadOnly
        ? hasUserPermission(Feature.EDIT_PARTNER_NAME_PUBLISHED)
        : hasUserPermission(Feature.EDIT_PARTNER_NAME_UNPUBLISHED),
      concessionOwnerId: {
        isShown: hasUserPermission(Feature.VIEW_PARTNER_CONCESSION_OWNER),
        isEditable:
          !partnerIsReadOnly &&
          hasUserPermission(Feature.EDIT_PARTNER_CONCESSION_OWNER_UNPUBLISHED),
      },
      businessRegionId: {
        isShown: hasUserPermission(Feature.VIEW_PARTNER_BUSINESS_REGION),
        isEditable:
          !partnerIsReadOnly &&
          hasUserPermission(Feature.EDIT_PARTNER_BUSINESS_REGION_UNPUBLISHED),
      },
      businessDevelopers: hasUserPermission(Feature.EDIT_PARTNER_BUSINESS_DEV_MANAGER),
      accessPackage: hasUserPermission(Feature.EDIT_PARTNER_DEFAULT_ACCESS),
      meansOfTransport: hasUserPermission(Feature.EDIT_PARTNER_DEFAULT_CAPACITY),
      currency: hasUserPermission(Feature.EDIT_PARTNER_CURRENCY),
    },
    capacityAndReportsInput: {
      defaultCirculationId:
        hasUserPermission(Feature.EDIT_PARTNER_DEFAULT_CAPACITY) && !isCreation,
      capacityDecreaseTimeFrameInHours: hasUserPermission(
        Feature.EDIT_PARTNER_CAPACITY_DECREASE_RULE
      ),
      dataPrivacyContractSigned: hasUserPermission(
        Feature.EDIT_PARTNER_DATA_PRIVACY_CONTRACT
      ),
      bookingInformationConfiguration: hasUserPermission(
        Feature.EDIT_PARTNER_PASSENGER_BOOKING_INFORMATION
      ),
    },
    customerServiceAndPoliciesInput: {
      checkInRequirements: hasUserPermission(Feature.EDIT_PARTNER_CHECK_IN_REQUIREMENTS),
      customerServiceContact: hasUserPermission(
        Feature.EDIT_PARTNER_CUSTOMER_SERVICE_INFORMATION
      ),
      termsAndConditionsUrl: hasUserPermission(Feature.EDIT_PARTNER_TERMS_AND_CONDITIONS),
    },
    brandsInput: {
      editBrand: hasUserPermission(Feature.EDIT_PARTNER_BRAND),
      policies: hasUserPermission(Feature.EDIT_PARTNER_BRAND_POLICIES),
    },
    settingsInput: {
      isApiPartner: hasUserPermission(Feature.EDIT_PARTNER_SETTINGS) && isCreation,
      icPricingRules: hasUserPermission(Feature.EDIT_PARTNER_IC_PRICING_RULES),
      allOtherSettings: hasUserPermission(Feature.EDIT_PARTNER_SETTINGS),
    },
    teamInput: hasUserPermission(Feature.EDIT_PARTNER_TEAMS),
    submitButton: true,
    deleteButton: {
      isShown: hasUserPermission(Feature.VIEW_DELETE_PARTNER),
      isEditable:
        !partnerIsReadOnly && hasUserPermission(Feature.DELETE_PARTNER_UNPUBLISHED),
    },
  };
};

export default getEditablePartnerFields;
