@use "variables" as *;

.label {
  color: $content-secondary-color;
}

.labelWrapper {
  background: $bg-primary-color;
  border-radius: $border-radius;
  font-size: $font-size-small;
  position: relative;
  top: -11px;
  z-index: 1;
  display: inline;
  line-height: 1em;
  padding: 1px $spacing-1;
  left: $spacing-1;

  @include on-bp(xl) {
    background: none;
    font-size: inherit;
    position: static;
    padding-bottom: $spacing-half;
  }
}

.info {
  position: relative;
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;
  display: block;
  padding-top: 3px;
  color: $danger-dark-color;
}

.narrow {
  width: 50%;
}
