// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import * as Types from "../../../shared/types/schema";

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
const defaultOptions = {} as const;
export type PartnerFragment = {
  __typename?: "Partner";
  id: string;
  name: string;
  concessionOwnerId?: string | null;
  dataPrivacyContractSigned: boolean;
  capacityDecreaseTimeFrameInHours?: number | null;
  termsAndConditionsUrl?: string | null;
  deletable: boolean;
  readOnly: boolean;
  priceChangeAllowed: boolean;
  priceDistribution: Types.PriceDistribution;
  externalInterconnectionPricingAllowed: boolean;
  accessPackage: Types.AccessPackage;
  meansOfTransport?: Types.MeansOfTransport | null;
  currency: Types.Currency;
  tags: Array<Types.PartnerTag>;
  businessRegion: { __typename?: "BusinessRegion"; id: string; name: string };
  businessDevelopers: Array<{
    __typename?: "BusinessDeveloper";
    name: string;
    email: string;
  }>;
  brands: Array<{
    __typename?: "Brand";
    id: string;
    name: string;
    created?: string | null;
    updated?: string | null;
    canBeRemovedFromPartner: boolean;
  }>;
  defaultCirculation?: { __typename?: "Circulation"; uuid: string } | null;
  bookingInformationConfiguration: {
    __typename?: "BookingInformationConfiguration";
    bookingInformation: Array<Types.BookingInformationField>;
    tripInformation: Array<Types.TripInformationField>;
    passengerInformation: Array<Types.PassengerInformationField>;
  };
  checkInRequirements?: {
    __typename?: "CheckInRequirements";
    proofOfIdentityRequired: Array<Types.ProofOfIdentity>;
    checkInTimeStart?: {
      __typename?: "TimeEntry";
      value: number;
      unit: Types.TimeUnit;
    } | null;
    checkInTimeEnd?: {
      __typename?: "TimeEntry";
      value: number;
      unit: Types.TimeUnit;
    } | null;
    checkInOptions: {
      __typename?: "CheckInOptions";
      website?: string | null;
      shop: boolean;
      selfServiceMachine: boolean;
      checkInDesk: {
        __typename?: "CheckInDesk";
        enabled: boolean;
        currency?: Types.Currency | null;
        feeUnit?: Types.CheckInDeskFeeUnit | null;
        fee: { __typename?: "MoneyEntry"; value: number };
      };
    };
  } | null;
  customerServiceContact: Array<{
    __typename?: "CustomerServiceContact";
    purposeOfContact?: Types.PurposeOfContactField | null;
    hotlineNumber?: string | null;
    email?: string | null;
    languagesSpoken: Array<Types.IsoLanguage>;
    timeZoneId?: string | null;
    workingHours: Array<{
      __typename?: "WorkingHours";
      workingDays: Array<Types.DayOfWeek>;
      from?: string | null;
      to?: string | null;
    }>;
    holidayWorkingHours: Array<{
      __typename?: "HolidayWorkingHours";
      from?: string | null;
      to?: string | null;
    }>;
  }>;
  apiSettings?: {
    __typename?: "ApiSettings";
    apiPartner: boolean;
    apiDisabledFeatures: { __typename?: "ApiDisabledFeatures"; manageCapacity: boolean };
    ticketSendout: {
      __typename?: "TicketSendout";
      enabled: boolean;
      checkInInformation: Array<Types.CheckInInformation>;
    };
  } | null;
  autoOnSaleConfig: {
    __typename?: "AutoOnSaleConfig";
    normalSchedule: boolean;
    conceptChangeSchedule: boolean;
    reason?: string | null;
  };
};

export type MobilityPartnerFragment = {
  __typename?: "MobilityPartner";
  uuid: string;
  name: string;
  inventoryRestriction: Types.InventoryRestriction;
  interconnectionRule?: {
    __typename?: "InterconnectionRule";
    type: Types.InterconnectionRuleType;
    operators: Array<{ __typename?: "Operator"; uuid: string; name: string }>;
  } | null;
};

export type PartnerDetailsQueryVariables = Types.Exact<{
  partnerId?: Types.InputMaybe<Types.Scalars["ID"]["input"]>;
}>;

export type PartnerDetailsQuery = {
  __typename?: "Query";
  findPartner: {
    __typename?: "Partner";
    id: string;
    name: string;
    concessionOwnerId?: string | null;
    dataPrivacyContractSigned: boolean;
    capacityDecreaseTimeFrameInHours?: number | null;
    termsAndConditionsUrl?: string | null;
    deletable: boolean;
    readOnly: boolean;
    priceChangeAllowed: boolean;
    priceDistribution: Types.PriceDistribution;
    externalInterconnectionPricingAllowed: boolean;
    accessPackage: Types.AccessPackage;
    meansOfTransport?: Types.MeansOfTransport | null;
    currency: Types.Currency;
    tags: Array<Types.PartnerTag>;
    businessRegion: { __typename?: "BusinessRegion"; id: string; name: string };
    businessDevelopers: Array<{
      __typename?: "BusinessDeveloper";
      name: string;
      email: string;
    }>;
    brands: Array<{
      __typename?: "Brand";
      id: string;
      name: string;
      created?: string | null;
      updated?: string | null;
      canBeRemovedFromPartner: boolean;
    }>;
    defaultCirculation?: { __typename?: "Circulation"; uuid: string } | null;
    bookingInformationConfiguration: {
      __typename?: "BookingInformationConfiguration";
      bookingInformation: Array<Types.BookingInformationField>;
      tripInformation: Array<Types.TripInformationField>;
      passengerInformation: Array<Types.PassengerInformationField>;
    };
    checkInRequirements?: {
      __typename?: "CheckInRequirements";
      proofOfIdentityRequired: Array<Types.ProofOfIdentity>;
      checkInTimeStart?: {
        __typename?: "TimeEntry";
        value: number;
        unit: Types.TimeUnit;
      } | null;
      checkInTimeEnd?: {
        __typename?: "TimeEntry";
        value: number;
        unit: Types.TimeUnit;
      } | null;
      checkInOptions: {
        __typename?: "CheckInOptions";
        website?: string | null;
        shop: boolean;
        selfServiceMachine: boolean;
        checkInDesk: {
          __typename?: "CheckInDesk";
          enabled: boolean;
          currency?: Types.Currency | null;
          feeUnit?: Types.CheckInDeskFeeUnit | null;
          fee: { __typename?: "MoneyEntry"; value: number };
        };
      };
    } | null;
    customerServiceContact: Array<{
      __typename?: "CustomerServiceContact";
      purposeOfContact?: Types.PurposeOfContactField | null;
      hotlineNumber?: string | null;
      email?: string | null;
      languagesSpoken: Array<Types.IsoLanguage>;
      timeZoneId?: string | null;
      workingHours: Array<{
        __typename?: "WorkingHours";
        workingDays: Array<Types.DayOfWeek>;
        from?: string | null;
        to?: string | null;
      }>;
      holidayWorkingHours: Array<{
        __typename?: "HolidayWorkingHours";
        from?: string | null;
        to?: string | null;
      }>;
    }>;
    apiSettings?: {
      __typename?: "ApiSettings";
      apiPartner: boolean;
      apiDisabledFeatures: {
        __typename?: "ApiDisabledFeatures";
        manageCapacity: boolean;
      };
      ticketSendout: {
        __typename?: "TicketSendout";
        enabled: boolean;
        checkInInformation: Array<Types.CheckInInformation>;
      };
    } | null;
    autoOnSaleConfig: {
      __typename?: "AutoOnSaleConfig";
      normalSchedule: boolean;
      conceptChangeSchedule: boolean;
      reason?: string | null;
    };
  };
};

export type FindMobilityPartnerDetailsQueryVariables = Types.Exact<{
  partnerId?: Types.InputMaybe<Types.Scalars["ID"]["input"]>;
}>;

export type FindMobilityPartnerDetailsQuery = {
  __typename?: "Query";
  findMobilityPartner?: {
    __typename?: "MobilityPartner";
    uuid: string;
    name: string;
    inventoryRestriction: Types.InventoryRestriction;
    interconnectionRule?: {
      __typename?: "InterconnectionRule";
      type: Types.InterconnectionRuleType;
      operators: Array<{ __typename?: "Operator"; uuid: string; name: string }>;
    } | null;
  } | null;
};

export type UpdatePartnerMutationVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
  accountInput?: Types.InputMaybe<Types.AccountInput>;
  capacityAndReportsInput?: Types.InputMaybe<Types.CapacityAndReportsInput>;
  customerServiceAndPoliciesInput?: Types.InputMaybe<Types.CustomerServiceAndPoliciesInput>;
  brandsInput?: Types.InputMaybe<Types.BrandsInput>;
  settingsInput?: Types.InputMaybe<Types.PartnerSettingsInput>;
  partnerTagsInput?: Types.InputMaybe<Types.PartnerTagsInput>;
}>;

export type UpdatePartnerMutation = {
  __typename?: "Mutation";
  updatePartner: {
    __typename?: "Partner";
    id: string;
    name: string;
    concessionOwnerId?: string | null;
    dataPrivacyContractSigned: boolean;
    capacityDecreaseTimeFrameInHours?: number | null;
    termsAndConditionsUrl?: string | null;
    deletable: boolean;
    readOnly: boolean;
    priceChangeAllowed: boolean;
    priceDistribution: Types.PriceDistribution;
    externalInterconnectionPricingAllowed: boolean;
    accessPackage: Types.AccessPackage;
    meansOfTransport?: Types.MeansOfTransport | null;
    currency: Types.Currency;
    tags: Array<Types.PartnerTag>;
    businessRegion: { __typename?: "BusinessRegion"; id: string; name: string };
    businessDevelopers: Array<{
      __typename?: "BusinessDeveloper";
      name: string;
      email: string;
    }>;
    brands: Array<{
      __typename?: "Brand";
      id: string;
      name: string;
      created?: string | null;
      updated?: string | null;
      canBeRemovedFromPartner: boolean;
    }>;
    defaultCirculation?: { __typename?: "Circulation"; uuid: string } | null;
    bookingInformationConfiguration: {
      __typename?: "BookingInformationConfiguration";
      bookingInformation: Array<Types.BookingInformationField>;
      tripInformation: Array<Types.TripInformationField>;
      passengerInformation: Array<Types.PassengerInformationField>;
    };
    checkInRequirements?: {
      __typename?: "CheckInRequirements";
      proofOfIdentityRequired: Array<Types.ProofOfIdentity>;
      checkInTimeStart?: {
        __typename?: "TimeEntry";
        value: number;
        unit: Types.TimeUnit;
      } | null;
      checkInTimeEnd?: {
        __typename?: "TimeEntry";
        value: number;
        unit: Types.TimeUnit;
      } | null;
      checkInOptions: {
        __typename?: "CheckInOptions";
        website?: string | null;
        shop: boolean;
        selfServiceMachine: boolean;
        checkInDesk: {
          __typename?: "CheckInDesk";
          enabled: boolean;
          currency?: Types.Currency | null;
          feeUnit?: Types.CheckInDeskFeeUnit | null;
          fee: { __typename?: "MoneyEntry"; value: number };
        };
      };
    } | null;
    customerServiceContact: Array<{
      __typename?: "CustomerServiceContact";
      purposeOfContact?: Types.PurposeOfContactField | null;
      hotlineNumber?: string | null;
      email?: string | null;
      languagesSpoken: Array<Types.IsoLanguage>;
      timeZoneId?: string | null;
      workingHours: Array<{
        __typename?: "WorkingHours";
        workingDays: Array<Types.DayOfWeek>;
        from?: string | null;
        to?: string | null;
      }>;
      holidayWorkingHours: Array<{
        __typename?: "HolidayWorkingHours";
        from?: string | null;
        to?: string | null;
      }>;
    }>;
    apiSettings?: {
      __typename?: "ApiSettings";
      apiPartner: boolean;
      apiDisabledFeatures: {
        __typename?: "ApiDisabledFeatures";
        manageCapacity: boolean;
      };
      ticketSendout: {
        __typename?: "TicketSendout";
        enabled: boolean;
        checkInInformation: Array<Types.CheckInInformation>;
      };
    } | null;
    autoOnSaleConfig: {
      __typename?: "AutoOnSaleConfig";
      normalSchedule: boolean;
      conceptChangeSchedule: boolean;
      reason?: string | null;
    };
  };
};

export type UpdateMobilityPartnerMutationVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
  inventoryRule?: Types.InputMaybe<Types.InventoryRuleInput>;
}>;

export type UpdateMobilityPartnerMutation = {
  __typename?: "Mutation";
  updateMobilityPartner?: {
    __typename?: "MobilityPartner";
    uuid: string;
    name: string;
    inventoryRestriction: Types.InventoryRestriction;
    interconnectionRule?: {
      __typename?: "InterconnectionRule";
      type: Types.InterconnectionRuleType;
      operators: Array<{ __typename?: "Operator"; uuid: string; name: string }>;
    } | null;
  } | null;
};

export type DeletePartnerMutationVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
}>;

export type DeletePartnerMutation = { __typename?: "Mutation"; deletePartner: boolean };

export const PartnerFragmentDoc = gql`
  fragment Partner on Partner {
    id
    name
    businessRegion {
      id
      name
    }
    concessionOwnerId
    businessDevelopers {
      name
      email
    }
    brands {
      id
      name
      created
      updated
      canBeRemovedFromPartner
    }
    defaultCirculation {
      uuid
    }
    dataPrivacyContractSigned
    bookingInformationConfiguration {
      bookingInformation
      tripInformation
      passengerInformation
    }
    capacityDecreaseTimeFrameInHours
    checkInRequirements {
      checkInTimeStart {
        value
        unit
      }
      checkInTimeEnd {
        value
        unit
      }
      checkInOptions {
        website
        shop
        checkInDesk {
          enabled
          currency
          fee {
            value
          }
          feeUnit
        }
        selfServiceMachine
      }
      proofOfIdentityRequired
    }
    customerServiceContact {
      purposeOfContact
      hotlineNumber
      email
      languagesSpoken
      workingHours {
        workingDays
        from
        to
      }
      timeZoneId
      holidayWorkingHours {
        from
        to
      }
    }
    termsAndConditionsUrl
    deletable
    readOnly
    priceChangeAllowed
    priceDistribution
    externalInterconnectionPricingAllowed
    apiSettings {
      apiPartner
      apiDisabledFeatures {
        manageCapacity
      }
      ticketSendout {
        enabled
        checkInInformation
      }
    }
    accessPackage
    autoOnSaleConfig {
      normalSchedule
      conceptChangeSchedule
      reason
    }
    meansOfTransport
    currency
    tags
  }
`;
export const MobilityPartnerFragmentDoc = gql`
  fragment MobilityPartner on MobilityPartner {
    uuid
    name
    inventoryRestriction
    interconnectionRule {
      type
      operators {
        uuid
        name
      }
    }
  }
`;
export const PartnerDetailsDocument = gql`
  query PartnerDetails($partnerId: ID) {
    findPartner(id: $partnerId) {
      ...Partner
    }
  }
  ${PartnerFragmentDoc}
`;

/**
 * __usePartnerDetailsQuery__
 *
 * To run a query within a React component, call `usePartnerDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerDetailsQuery({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function usePartnerDetailsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    PartnerDetailsQuery,
    PartnerDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PartnerDetailsQuery, PartnerDetailsQueryVariables>(
    PartnerDetailsDocument,
    options
  );
}
export function usePartnerDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PartnerDetailsQuery,
    PartnerDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PartnerDetailsQuery, PartnerDetailsQueryVariables>(
    PartnerDetailsDocument,
    options
  );
}
export function usePartnerDetailsSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        PartnerDetailsQuery,
        PartnerDetailsQueryVariables
      >
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    PartnerDetailsQuery,
    PartnerDetailsQueryVariables
  >(PartnerDetailsDocument, options);
}
export type PartnerDetailsQueryHookResult = ReturnType<typeof usePartnerDetailsQuery>;
export type PartnerDetailsLazyQueryHookResult = ReturnType<
  typeof usePartnerDetailsLazyQuery
>;
export type PartnerDetailsSuspenseQueryHookResult = ReturnType<
  typeof usePartnerDetailsSuspenseQuery
>;
export const FindMobilityPartnerDetailsDocument = gql`
  query FindMobilityPartnerDetails($partnerId: ID) {
    findMobilityPartner(partnerId: $partnerId) {
      ...MobilityPartner
    }
  }
  ${MobilityPartnerFragmentDoc}
`;

/**
 * __useFindMobilityPartnerDetailsQuery__
 *
 * To run a query within a React component, call `useFindMobilityPartnerDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindMobilityPartnerDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindMobilityPartnerDetailsQuery({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useFindMobilityPartnerDetailsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FindMobilityPartnerDetailsQuery,
    FindMobilityPartnerDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FindMobilityPartnerDetailsQuery,
    FindMobilityPartnerDetailsQueryVariables
  >(FindMobilityPartnerDetailsDocument, options);
}
export function useFindMobilityPartnerDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindMobilityPartnerDetailsQuery,
    FindMobilityPartnerDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FindMobilityPartnerDetailsQuery,
    FindMobilityPartnerDetailsQueryVariables
  >(FindMobilityPartnerDetailsDocument, options);
}
export function useFindMobilityPartnerDetailsSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        FindMobilityPartnerDetailsQuery,
        FindMobilityPartnerDetailsQueryVariables
      >
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    FindMobilityPartnerDetailsQuery,
    FindMobilityPartnerDetailsQueryVariables
  >(FindMobilityPartnerDetailsDocument, options);
}
export type FindMobilityPartnerDetailsQueryHookResult = ReturnType<
  typeof useFindMobilityPartnerDetailsQuery
>;
export type FindMobilityPartnerDetailsLazyQueryHookResult = ReturnType<
  typeof useFindMobilityPartnerDetailsLazyQuery
>;
export type FindMobilityPartnerDetailsSuspenseQueryHookResult = ReturnType<
  typeof useFindMobilityPartnerDetailsSuspenseQuery
>;
export const UpdatePartnerDocument = gql`
  mutation UpdatePartner(
    $id: ID!
    $accountInput: AccountInput
    $capacityAndReportsInput: CapacityAndReportsInput
    $customerServiceAndPoliciesInput: CustomerServiceAndPoliciesInput
    $brandsInput: BrandsInput
    $settingsInput: PartnerSettingsInput
    $partnerTagsInput: PartnerTagsInput
  ) {
    updatePartner(
      id: $id
      accountInput: $accountInput
      capacityAndReportsInput: $capacityAndReportsInput
      customerServiceAndPoliciesInput: $customerServiceAndPoliciesInput
      brandsInput: $brandsInput
      settingsInput: $settingsInput
      partnerTagsInput: $partnerTagsInput
    ) {
      ...Partner
    }
  }
  ${PartnerFragmentDoc}
`;

/**
 * __useUpdatePartnerMutation__
 *
 * To run a mutation, you first call `useUpdatePartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartnerMutation, { data, loading, error }] = useUpdatePartnerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      accountInput: // value for 'accountInput'
 *      capacityAndReportsInput: // value for 'capacityAndReportsInput'
 *      customerServiceAndPoliciesInput: // value for 'customerServiceAndPoliciesInput'
 *      brandsInput: // value for 'brandsInput'
 *      settingsInput: // value for 'settingsInput'
 *      partnerTagsInput: // value for 'partnerTagsInput'
 *   },
 * });
 */
export function useUpdatePartnerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePartnerMutation,
    UpdatePartnerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdatePartnerMutation,
    UpdatePartnerMutationVariables
  >(UpdatePartnerDocument, options);
}
export type UpdatePartnerMutationHookResult = ReturnType<typeof useUpdatePartnerMutation>;
export const UpdateMobilityPartnerDocument = gql`
  mutation UpdateMobilityPartner($id: ID!, $inventoryRule: InventoryRuleInput) {
    updateMobilityPartner(id: $id, inventoryRule: $inventoryRule) {
      ...MobilityPartner
    }
  }
  ${MobilityPartnerFragmentDoc}
`;

/**
 * __useUpdateMobilityPartnerMutation__
 *
 * To run a mutation, you first call `useUpdateMobilityPartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMobilityPartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMobilityPartnerMutation, { data, loading, error }] = useUpdateMobilityPartnerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      inventoryRule: // value for 'inventoryRule'
 *   },
 * });
 */
export function useUpdateMobilityPartnerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateMobilityPartnerMutation,
    UpdateMobilityPartnerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateMobilityPartnerMutation,
    UpdateMobilityPartnerMutationVariables
  >(UpdateMobilityPartnerDocument, options);
}
export type UpdateMobilityPartnerMutationHookResult = ReturnType<
  typeof useUpdateMobilityPartnerMutation
>;
export const DeletePartnerDocument = gql`
  mutation DeletePartner($id: ID!) {
    deletePartner(id: $id)
  }
`;

/**
 * __useDeletePartnerMutation__
 *
 * To run a mutation, you first call `useDeletePartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePartnerMutation, { data, loading, error }] = useDeletePartnerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePartnerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeletePartnerMutation,
    DeletePartnerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeletePartnerMutation,
    DeletePartnerMutationVariables
  >(DeletePartnerDocument, options);
}
export type DeletePartnerMutationHookResult = ReturnType<typeof useDeletePartnerMutation>;
