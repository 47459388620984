import { compareDesc } from "date-fns";
import getAggregatedScheduleStatus from "../../../../shared/helpers/getAggregatedScheduleStatus/getAggregatedScheduleStatus";
import { InvisibleConceptChange } from "../../types";

const statusOrder = {
  ON_SALE: 0,
  PROCESSING: 1,
  HIDDEN: 2,
  IN_REVIEW: 3,
  DRAFT: 4,
  INACTIVE: 5,
};

export default (cc1: InvisibleConceptChange, cc2: InvisibleConceptChange): number => {
  const cc1Status = getAggregatedScheduleStatus(cc1.schedule.displayedScheduleStatus);
  const cc2Status = getAggregatedScheduleStatus(cc2.schedule.displayedScheduleStatus);

  const statusCompare = statusOrder[cc1Status] - statusOrder[cc2Status];

  return statusCompare === 0
    ? compareDesc(cc1.uiPeriod.start, cc2.uiPeriod.start)
    : statusCompare;
};
