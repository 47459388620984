import { defaultRangeExtractor, useVirtualizer } from "@tanstack/react-virtual";
import * as React from "react";
import getAllStartIndexesPerRow from "../../helper/getAllStartIndexesPerRow/getAllStartIndexesPerRow";
import {
  calculateRowHeight as calculateCalendarRowHeight,
  contentCellWidthDaily,
  contentCellWidthMonthly,
  headerHeight,
  stickyHeaderHeight,
} from "../../helper/sizes/sizes";
import { Calendar, CalendarIntervalType } from "../../types";
import CalendarContent from "../calendarContent/CalendarContent";
import DayHeaderCell from "../dayHeaderCell/DayHeaderCell";
import MonthHeaderCell from "../monthHeaderCell/MonthHeaderCell";
import ScrollNavigation from "../scrollNavigation/ScrollNavigation";
import StickyHeaderRow from "../stickyHeaderRow/StickyHeaderRow";
import ZoomControl from "../zoomControl/ZoomControl";
import * as css from "./CalendarVirtualizer.scss";

export type CalendarVirtualizerProps = {
  calendar: Calendar;
  displayDates: Date[];
  stickyHeaderDates: Date[];
  initialOffsetInPixel: number;
  initialOffsetIndex: number;
  onIntervalTypeChange: (selectedIntervalType: CalendarIntervalType) => void;
  expandedRows: number[];
};

const CalendarVirtualizer: React.FC<CalendarVirtualizerProps> = ({
  calendar,
  displayDates,
  stickyHeaderDates,
  initialOffsetInPixel,
  initialOffsetIndex,
  onIntervalTypeChange,
  expandedRows,
}) => {
  const isDaily = calendar.intervalType === CalendarIntervalType.DAILY;
  const parentRef = React.useRef(null);

  const startIndexes = getAllStartIndexesPerRow(calendar, displayDates);

  const colVirtualizer = useVirtualizer({
    count: displayDates.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => (isDaily ? contentCellWidthDaily : contentCellWidthMonthly),
    overscan: 5,
    horizontal: true,
    initialOffset: initialOffsetInPixel,
    rangeExtractor: React.useCallback((range) => {
      const hoverContainerIndexes: number[] = [];

      startIndexes.forEach((row) => {
        row.forEach((col) => {
          if (
            col < range.startIndex - range.overscan &&
            hoverContainerIndexes.indexOf(col) === -1
          ) {
            hoverContainerIndexes.push(col);
          }
        });
      });

      return [...hoverContainerIndexes.sort(), ...defaultRangeExtractor(range)];
    }, []),
  });

  const rowVirtualizer = useVirtualizer({
    count: calendar.rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 0,
    overscan: 1,
    horizontal: false,
  });

  const scroll = (index: number) => {
    colVirtualizer.scrollToIndex(index, {
      align: "start",
      behavior: "smooth",
    });
  };

  calendar.rows.forEach((calendarRow, index) => {
    const expanded = expandedRows.includes(index);
    const rowHeight = calculateCalendarRowHeight(calendarRow, expanded);

    rowVirtualizer.resizeItem(index, rowHeight);
  });

  return (
    <>
      {colVirtualizer.range && (
        <div className={css.controlElements}>
          <ZoomControl
            onIntervalTypeChange={onIntervalTypeChange}
            selectedIntervalType={calendar.intervalType}
          />

          <ScrollNavigation
            onScroll={(steps) => {
              scroll(colVirtualizer.range!.startIndex + steps);
            }}
            scrollToInitialOffset={() => {
              scroll(initialOffsetIndex);
            }}
            initialOffsetIndex={initialOffsetIndex}
            currentStartIndex={colVirtualizer.range.startIndex}
            currentEndIndex={colVirtualizer.range.endIndex}
            lastItemIndex={displayDates.length - 1}
            fastScrollSpeed={isDaily ? 18 : 11}
          />
        </div>
      )}

      <div className={css.container}>
        <div
          className={css.scrollContainerWrapper}
          ref={parentRef}
          style={{
            height: `${rowVirtualizer.getTotalSize() + headerHeight + stickyHeaderHeight}px`,
          }}
        >
          <div
            className={css.scrollContainer}
            style={{
              width: `${colVirtualizer.getTotalSize()}px`,
            }}
            data-id="scroll-container"
          >
            <StickyHeaderRow
              displayDates={stickyHeaderDates}
              parentRef={parentRef}
              initialOffset={initialOffsetInPixel}
              intervalType={calendar.intervalType}
            />

            {colVirtualizer.getVirtualItems().map((virtualItem) => {
              if (isDaily) {
                return (
                  <DayHeaderCell
                    key={virtualItem.index}
                    currentDate={displayDates[virtualItem.index]}
                    cellWidth={virtualItem.size}
                    colStart={virtualItem.start}
                  />
                );
              }

              return (
                <MonthHeaderCell
                  key={virtualItem.index}
                  currentDate={displayDates[virtualItem.index]}
                  cellWidth={virtualItem.size}
                  colStart={virtualItem.start}
                />
              );
            })}
            <div className={css.contentWrapper}>
              <CalendarContent
                calendar={calendar}
                displayDates={displayDates}
                rows={rowVirtualizer.getVirtualItems()}
                columns={colVirtualizer.getVirtualItems()}
              />
            </div>
          </div>
        </div>
        <div id="calendar-expand-button-portal" />
      </div>
    </>
  );
};

export default CalendarVirtualizer;
