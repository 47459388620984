import getAggregatedScheduleStatus from "../../helper/getAggregatedScheduleStatus/getAggregatedScheduleStatus";
import { AggregatedScheduleStatus, CalendarSchedule } from "../../types";
import * as css from "./ScheduleCell.scss";

const getCssClass = (schedule: CalendarSchedule): string => {
  const aggregatedScheduleStatus = getAggregatedScheduleStatus(
    schedule.displayedScheduleStatus
  );

  switch (aggregatedScheduleStatus) {
    case AggregatedScheduleStatus.DRAFT:
      return css.draftCell;
    case AggregatedScheduleStatus.IN_REVIEW:
      return css.inReviewCell;
    case AggregatedScheduleStatus.PROCESSING:
      return css.processingCell;
    case AggregatedScheduleStatus.HIDDEN:
      return css.hiddenCell;
    case AggregatedScheduleStatus.ON_SALE:
      return css.onSaleCell;
    case AggregatedScheduleStatus.INACTIVE:
      return css.inactiveCell;
    default:
      return "";
  }
};

export default getCssClass;
