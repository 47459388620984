import { Infobox } from "@flixbus/honeycomb-react";
import { DefaultInfoAlert } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ConfirmationPopup from "../../../../shared/components/confirmationPopup/ConfirmationPopup";
import { Period, Schedule } from "../../../../shared/types/schema";
import {
  FindSourceAndDestinationSchedulesForImportQuery,
  useFindSourceAndDestinationSchedulesForImportLazyQuery,
} from "../../api/operations.generated";
import ImportPricingPopupBody from "../importPricingPopupBody/ImportPricingPopupBody";
import * as css from "./ImportPricingPopup.scss";

export type ImportPricingPopupProps = {
  scheduleId: Schedule["id"];
  scheduleHasPrices: boolean;
  onPricingImport: (
    destinationSchedule: FindSourceAndDestinationSchedulesForImportQuery["findSourceSchedule"],
    sourceSchedule: FindSourceAndDestinationSchedulesForImportQuery["findDestinationSchedule"],
    selectedPricingPeriod: Period["start"]
  ) => void;
  onCancel: () => void;
};

const ImportPricingPopup: React.FC<ImportPricingPopupProps> = ({
  scheduleId,
  scheduleHasPrices,
  onPricingImport,
  onCancel,
}) => {
  const { formatMessage } = useIntl();
  const [selectedSchedule, setSelectedSchedule] = React.useState<Schedule["id"]>();
  const [selectedPeriod, setSelectedPeriod] = React.useState<string>();

  const [findSchedulesForImport, { loading, error }] =
    useFindSourceAndDestinationSchedulesForImportLazyQuery({
      onCompleted: (data) => {
        onPricingImport(
          data.findDestinationSchedule,
          data.findSourceSchedule,
          selectedPeriod
        );
      },
    });

  return (
    <ConfirmationPopup
      active
      titleText={formatMessage({ id: "prices.import.popup.title" })}
      onConfirm={() => {
        if (selectedSchedule && selectedPeriod) {
          findSchedulesForImport({
            variables: {
              destinationScheduleId: scheduleId,
              sourceScheduleId: selectedSchedule,
            },
          });
        }
      }}
      onCancel={onCancel}
      allowOverflow
      confirmButtonDisabled={!selectedSchedule || !selectedPeriod}
      confirmButtonLoading={loading}
    >
      <div className={css.popupBody}>
        {scheduleHasPrices && (
          <Infobox
            extraClasses={css.infoBox}
            appearance="warning"
            id="import-pricing-overwriting-warning"
            strong
          >
            <FormattedMessage id="prices.import.popup.info.valuesReplaced" />
          </Infobox>
        )}

        <ImportPricingPopupBody
          scheduleId={scheduleId}
          onScheduleSelected={(value) => setSelectedSchedule(value)}
          onPricingPeriodSelected={(value) => setSelectedPeriod(value)}
        />

        {error && (
          <DefaultInfoAlert
            data-id="import-has-error"
            message={formatMessage({ id: "general.somethingWentWrong" })}
          />
        )}
      </div>
    </ConfirmationPopup>
  );
};

export default ImportPricingPopup;
