import { IconArrowLeft, IconArrowRight } from "@flixbus/honeycomb-icons-react";
import { Button } from "@flixbus/honeycomb-react";
import {
  DefaultInfoAlert,
  Feature,
  commonPathnames,
  hasUserPermission,
} from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import buildPath from "../../routes/buildPath/buildPath";
import FormWizzard from "../../shared/components/formWizzard/FormWizzard";
import FormikButtons from "../../shared/components/formikButtons/FormikButtons";
import getEditableLineFields from "../../shared/helpers/getEditableFormFields/getEditableLineFields/getEditableLineFields";
import { LineEditableFields } from "../../shared/helpers/getEditableFormFields/types";
import { MutationNewLineArgs } from "../../shared/types/schema";
import LineCapacityAndAmenitiesForm from "../lineCapacityAndAmenitiesForm/LineCapacityAndAmenitiesForm";
import LineForbiddenRelationsForm from "../lineForbiddenRelationsForm/LineForbiddenRelationsForm";
import { ForbiddenRelationsFormValues } from "../lineForbiddenRelationsForm/types";
import LineGeneralSettingsForm from "../lineGeneralSettingsForm/LineGeneralSettingsForm";
import {
  useFindPartnerDetailsForLineCreationQuery,
  useNewLineMutation,
} from "./api/operations.generated";

export type LineValues = Omit<MutationNewLineArgs, "partnerId" | "forbiddenRelations"> & {
  forbiddenRelations: ForbiddenRelationsFormValues[];
};

type Props = {
  partnerId: string;
};

const CreateLine: React.FC<Props> = ({ partnerId }) => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const {
    data: queryData,
    loading: queryLoading,
    error: queryError,
  } = useFindPartnerDetailsForLineCreationQuery({
    variables: {
      id: partnerId,
    },
  });

  const [addLine, { error: mutationError, loading: mutationLoading }] =
    useNewLineMutation({
      onError: () => {},
      onCompleted: (data) => {
        navigate(buildPath.createSchedule(data.newLine.id));
      },
    });

  const handleCreateLine = (values: LineValues) => {
    addLine({
      variables: {
        partnerId,
        ...values,
        forbiddenRelations: values.forbiddenRelations.map((relation) => ({
          fromId: relation.from.id,
          toId: relation.to.id,
        })),
      },
    });
  };

  const handleCancel = () => {
    if (hasUserPermission(Feature.VIEW_PARTNER_LIST)) {
      navigate(buildPath.showPartnerDetail(partnerId));
    } else {
      navigate(commonPathnames.userHome);
    }
  };

  const isDuplicatedLineCodeError = mutationError?.graphQLErrors
    .map((error) => error.extensions?.errorCode)
    .includes(409);

  if (mutationError && !isDuplicatedLineCodeError) {
    return <DefaultInfoAlert message={mutationError.message} />;
  }

  const editableFields = getEditableLineFields(false, false, true);

  return (
    <FormWizzard<
      LineValues,
      LineEditableFields,
      {
        partnerId?: string;
        isUsedForCreation?: boolean;
        hasDuplicatedLineCodeError?: boolean;
      }
    >
      steps={[
        {
          title: formatMessage({ id: "lineForm.tab.general" }),
          Form: LineGeneralSettingsForm,
          key: "generalSettings",
          children: (
            <FormikButtons
              submitButtonText={formatMessage({ id: "general.next" })}
              submitButtonAppearance="secondary"
              submitButtonIcon={IconArrowRight}
              submitButtonLoading={queryLoading}
            >
              <Button data-id="cancel-button" onClick={handleCancel}>
                <FormattedMessage id="general.cancel" />
              </Button>
            </FormikButtons>
          ),
          editableFields: editableFields.generalSettings,
          defaultValues: queryData?.findPartner
            ? {
                meansOfTransport: queryData.findPartner.meansOfTransport || undefined,
              }
            : undefined,
          isLoadingData: queryLoading,
          dataLoadingHasError: !!queryError,
          partnerId,
          isUsedForCreation: true,
          hasDuplicatedLineCodeError: isDuplicatedLineCodeError,
        },
        {
          title: formatMessage({ id: "lineForm.tab.capacityAndAmenities" }),
          Form: LineCapacityAndAmenitiesForm,
          key: "capacityAndAmenities",
          children: (
            <FormikButtons
              submitButtonText={formatMessage({ id: "general.next" })}
              submitButtonAppearance="secondary"
              submitButtonIcon={IconArrowRight}
              resetButtonText={formatMessage({ id: "general.back" })}
              resetButtonAppearance={undefined}
              resetButtonIcon={IconArrowLeft}
            >
              <Button onClick={handleCancel}>
                <FormattedMessage id="general.cancel" />
              </Button>
            </FormikButtons>
          ),
          editableFields: editableFields.capacityAndAmenities,
          partnerId,
          defaultValues: queryData?.findPartner
            ? {
                defaultCirculationId:
                  queryData.findPartner.defaultCirculation?.uuid || undefined,
              }
            : undefined,
        },
        {
          title: formatMessage({ id: "general.forbiddenRelations" }),
          Form: LineForbiddenRelationsForm,
          key: "forbiddenRelations",
          children: (
            <FormikButtons
              submitButtonText={formatMessage({ id: "line.create" })}
              submitButtonAppearance="primary"
              submitButtonLoading={mutationLoading}
              resetButtonText={formatMessage({ id: "general.back" })}
              resetButtonAppearance={undefined}
              resetButtonIcon={IconArrowLeft}
            >
              <Button onClick={handleCancel}>
                <FormattedMessage id="general.cancel" />
              </Button>
            </FormikButtons>
          ),
          editableFields: editableFields.forbiddenRelations,
        },
      ]}
      onSubmit={handleCreateLine}
      showStep={isDuplicatedLineCodeError ? 0 : undefined}
    />
  );
};

export default CreateLine;
