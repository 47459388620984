/* 
We are using getDerivedStateFromError here which is not yet implemented
as hooks. That's why we need a class based component
https://reactjs.org/docs/hooks-faq.html#do-hooks-cover-all-use-cases-for-classes
*/
import { datadogRum } from "@datadog/browser-rum";
import * as React from "react";
import { ErrorMessages, ErrorTypes } from "../ErrorTypes";
import ErrorPage from "../errorPage/ErrorPage";

type State = {
  errorType?: ErrorTypes;
  isUnloading: boolean;
};

class ErrorBoundary extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = { errorType: undefined, isUnloading: false };
    this.setIsUnloading = this.setIsUnloading.bind(this);
  }

  static getDerivedStateFromError(error: Error) {
    if (error.message === ErrorMessages.e500) {
      return { errorType: ErrorTypes.e500 };
    }

    if (error.message === ErrorMessages.e403) {
      return { errorType: ErrorTypes.e403 };
    }

    if (error.message === ErrorMessages.e404) {
      return { errorType: ErrorTypes.e404 };
    }

    return { errorType: undefined };
  }

  componentDidMount() {
    window.addEventListener("beforeunload", this.setIsUnloading);
  }

  componentDidCatch(error: Error) {
    const { errorType, isUnloading } = this.state;

    if (errorType && !isUnloading) {
      datadogRum.addError(error, { type: errorType });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.setIsUnloading);
  }

  setIsUnloading() {
    this.setState({ isUnloading: true });
  }

  render() {
    const { errorType, isUnloading } = this.state;
    const { children } = this.props;

    if (errorType && !isUnloading) {
      return <ErrorPage type={errorType} />;
    }

    return children;
  }
}

export default ErrorBoundary;
