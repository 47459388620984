import { Heading } from "@flixbus/honeycomb-react";
import {
  DefaultInfoAlert,
  Feature,
  hasUserPermission,
} from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Grid, GridTopLeft, GridTopRight } from "../../shared/components/grid/Grid";
import LoadingAnimation from "../../shared/components/loadingAnimation/LoadingAnimation";
import StatusFilter from "../../shared/components/statusFilter/StatusFilter";
import NoSchedulesFoundMessage from "../scheduleCalendar/ui/noSchedulesFoundMessage/NoSchedulesFoundMessage";
import ScheduleAddButton from "../scheduleCalendar/ui/scheduleAddButton/ScheduleAddButton";
import { useSchedulesOfLineQuery } from "./api/operations.generated";
import filterActiveSchedules from "./helpers/filterActiveSchedules/filterActiveSchedules";
import ScheduleOverview from "./ui/scheduleOverview/ScheduleOverview";

type Props = {
  lineId: string;
  showHeaderContent: boolean;
};

const LineScheduleList: React.FC<Props> = ({ lineId, showHeaderContent }) => {
  const { formatMessage } = useIntl();
  const [showInactive, setShowInactive] = React.useState(false);

  const { loading, error, data } = useSchedulesOfLineQuery({
    variables: { lineId },
    fetchPolicy: "cache-and-network",
  });

  if (loading) {
    return <LoadingAnimation />;
  }

  if (data?.findLine) {
    const { schedules } = data.findLine;

    if (schedules.length) {
      const originalSchedules = schedules.filter((s) => !s.conceptChangeOf);
      const filteredSchedules = filterActiveSchedules(originalSchedules);
      const hasInactiveSchedules = filteredSchedules.length < originalSchedules.length;
      const displayedSchedules = showInactive ? originalSchedules : filteredSchedules;

      return (
        <>
          {showHeaderContent && (
            <Grid>
              <GridTopLeft>
                <Heading size={2} flushSpace>
                  <FormattedMessage id="lineDetail.schedules.headline" />
                </Heading>
                <StatusFilter
                  label={formatMessage({ id: "lineDetail.schedules.statusFilter.label" })}
                  checked={showInactive}
                  onChange={() => setShowInactive((prevState) => !prevState)}
                  disabled={!hasInactiveSchedules}
                  tooltipText={formatMessage({
                    id: "lineDetail.schedules.filter.disabled.tooltip",
                  })}
                />
              </GridTopLeft>
              <GridTopRight>
                {hasUserPermission(Feature.CREATE_SCHEDULE) && (
                  <ScheduleAddButton lineId={lineId} />
                )}
              </GridTopRight>
            </Grid>
          )}

          {displayedSchedules.length === 0 && (
            <NoSchedulesFoundMessage lineId={lineId} noActiveSchedules />
          )}
          {displayedSchedules.length > 0 && (
            <ScheduleOverview schedules={displayedSchedules} />
          )}
        </>
      );
    }
    return <NoSchedulesFoundMessage lineId={lineId} />;
  }

  if (error) {
    return <DefaultInfoAlert message={error.message} />;
  }

  return <></>;
};

export default LineScheduleList;
