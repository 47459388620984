@use "variables" as *;

.wrapper {
  padding: 0 $spacing-2;
}

.nameAndStatusWrapper {
  white-space: nowrap;
  display: flex;
  gap: 6px;
  margin-bottom: $spacing-2;
}
