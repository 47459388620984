@use "variables" as *;
@use "../../helper/styles/styles.scss";
@use "../../../../shared/styles/schedule-status.scss";

.scrollableColumn {
  @include styles.scrollableColumn;
  height: styles.$contentCellHeight;
  padding: calc((styles.$contentCellHeight - styles.$scheduleCellHeight) / 2) 0;

  border-right: styles.$border-light;
  border-bottom: $border;

  &.hasInvisibleConceptChanges {
    background-color: $bg-secondary-color;
    border-bottom: none;
    height: calc(styles.$contentCellHeight + styles.$expandButtonRowHeight);
  }
}

.weekend {
  @include styles.weekendCell;
}

.scheduleLink {
  text-decoration: inherit;
  color: inherit;
  display: block;
  height: 0;
}

.draftCell {
  background: schedule-status.$draftBackground;
  border-top: schedule-status.$draftBorder;
  border-bottom: schedule-status.$draftBorder;

  &.start {
    border-left: schedule-status.$draftBorder;
  }

  &.end {
    border-right: schedule-status.$draftBorder;
  }
}

.inReviewCell {
  background: schedule-status.$inReviewBackground;
  border-top: schedule-status.$inReviewBorder;
  border-bottom: schedule-status.$inReviewBorder;

  &.start {
    border-left: schedule-status.$inReviewBorder;
  }

  &.end {
    border-right: schedule-status.$inReviewBorder;
  }
}

.processingCell {
  background: schedule-status.$processingBackground;
  border-top: schedule-status.$processingBorder;
  border-bottom: schedule-status.$processingBorder;

  &.start {
    border-left: schedule-status.$processingBorder;
  }

  &.end {
    border-right: schedule-status.$processingBorder;
  }
}

.hiddenCell {
  background: schedule-status.$hiddenBackground;
  border-top: schedule-status.$hiddenBorder;
  border-bottom: schedule-status.$hiddenBorder;

  &.start {
    border-left: schedule-status.$hiddenBorder;
  }

  &.end {
    border-right: schedule-status.$hiddenBorder;
  }

  [class^="hcr-theme-flix-dark"] & {
    + .scheduleName {
      color: #353535;
    }
  }
}

.onSaleCell {
  background: schedule-status.$onSaleBackground;
  border-top: schedule-status.$onSaleBorder;
  border-bottom: schedule-status.$onSaleBorder;

  &.start {
    border-left: schedule-status.$onSaleBorder;
  }

  &.end {
    border-right: schedule-status.$onSaleBorder;
  }

  [class^="hcr-theme-flix-dark"] & {
    + .scheduleName {
      color: #353535;
    }
  }
}

.inactiveCell {
  background: schedule-status.$inactiveBackground;
  border-top: schedule-status.$inactiveBorder;
  border-bottom: schedule-status.$inactiveBorder;

  &.start {
    border-left: schedule-status.$inactiveBorder;
  }

  &.end {
    border-right: schedule-status.$inactiveBorder;
  }
}

.scheduleCell {
  height: styles.$scheduleCellHeight;

  // to overwrite parent border
  position: relative;
  margin: -1px;

  &.start {
    border-top-left-radius: styles.$border-radius-lg;
    border-bottom-left-radius: styles.$border-radius-lg;
  }

  &.isFirst {
    border-left-width: 8px;
  }

  &.end {
    border-top-right-radius: styles.$border-radius-lg;
    border-bottom-right-radius: styles.$border-radius-lg;
  }

  &.highlight {
    border-color: $focus-outline-color;
    border-top-width: 2px;
    border-bottom-width: 2px;
    border-right-width: 2px;
  }
}

.scheduleName {
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: $spacing-2;
  height: styles.$scheduleCellHeight;
  top: calc(-1 * styles.$scheduleCellHeight);
  line-height: calc(styles.$scheduleCellHeight + 2px);

  &.hasOverlapTooltip {
    padding-right: 25px;
  }
}
