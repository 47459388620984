import { ButtonGroup, Heading, Text } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import img401 from "../../../../assets/illustrations/access_forbidden.svg";
import img404 from "../../../../assets/illustrations/binoculars.svg";
import img500 from "../../../../assets/illustrations/error.svg";
import Feature from "../../../auth/permissions/Feature";
import { hasUserPermission } from "../../../auth/permissions/permissions";
import HideMainNav from "../../hideMainNav/HideMainNav";
import { ErrorTypes } from "../ErrorTypes";
import * as css from "./ErrorPage.scss";
import BackToMainPageButton from "./ui/backToMainPageButton/BackToMainPageButton";
import LogoutButton from "./ui/logoutButton/LogoutButton";
import OpenTicketLoginButton from "./ui/openTicketLoginButton/OpenTicketLoginButton";
import OpenTicketProblemButton from "./ui/openTicketProblemButton/OpenTicketProblemButton";
import RetryButton from "./ui/retryButton/RetryButton";

type Props = {
  type: ErrorTypes;
};

const ErrorPage: React.FC<Props> = ({ type }) => {
  const { formatMessage } = useIntl();

  let image;
  let headline;
  let text1;
  let text2;
  let button1;
  let button2;

  switch (type) {
    case ErrorTypes.e401:
      image = img401;
      headline = formatMessage({ id: "error.401.secondHeadline" });
      text1 = formatMessage({ id: "error.401403.description.top" });
      text2 = hasUserPermission(Feature.USER_HAS_SERVICEDESK_ACCESS)
        ? formatMessage({ id: "error.401403.description.bottom.bd" })
        : formatMessage({ id: "error.401403.description.bottom" });
      button1 = <RetryButton primary={false} />;
      button2 = hasUserPermission(Feature.USER_HAS_SERVICEDESK_ACCESS) ? (
        <OpenTicketLoginButton primary />
      ) : (
        <LogoutButton primary />
      );
      break;
    case ErrorTypes.e403:
      image = img401;
      headline = formatMessage({ id: "error.403.secondHeadline" });
      text1 = formatMessage({ id: "error.401403.description.top" });
      text2 = hasUserPermission(Feature.USER_HAS_SERVICEDESK_ACCESS)
        ? formatMessage({ id: "error.401403.description.bottom.bd" })
        : formatMessage({ id: "error.401403.description.bottom" });
      button1 = <RetryButton primary={false} />;
      button2 = hasUserPermission(Feature.USER_HAS_SERVICEDESK_ACCESS) ? (
        <OpenTicketLoginButton primary />
      ) : (
        <LogoutButton primary />
      );
      break;
    case ErrorTypes.e404:
      image = img404;
      headline = formatMessage({ id: "error.404.secondHeadline" });
      text1 = formatMessage({ id: "error.404.description" });
      text2 = (
        <>
          <FormattedMessage id="error.404.description.bottom" />
          <ul>
            <li key="one">
              <FormattedMessage id="error.404.list.item.top" />
            </li>
            <li key="two">
              <FormattedMessage id="error.404.list.item.bottom" />
            </li>
          </ul>
        </>
      );
      button1 = <BackToMainPageButton primary />;
      button2 = <></>;
      break;
    case ErrorTypes.e500:
      image = img500;
      headline = formatMessage({ id: "error.500.secondHeadline" });
      text1 = formatMessage({ id: "error.500.description.top" });
      text2 = hasUserPermission(Feature.USER_HAS_SERVICEDESK_ACCESS)
        ? formatMessage({ id: "error.500.description.bottom" })
        : "";
      button1 = hasUserPermission(Feature.USER_HAS_SERVICEDESK_ACCESS) ? (
        <OpenTicketProblemButton primary={false} />
      ) : (
        <LogoutButton primary={false} />
      );
      button2 = <RetryButton primary />;
      break;
    default:
      break;
  }

  return (
    <div data-status-code={type} className={css.background}>
      <div className={css.imageContainer}>
        <img src={image} alt="" />
      </div>
      <div className={css.textContainer}>
        <Heading extraClasses={css.headline} size={1}>
          {type}
        </Heading>
        <Heading extraClasses={css.headline} size={1}>
          {headline}
        </Heading>
        <Text extraClasses={css.descriptionText}>
          {text1}
          <br />
          {text2}
        </Text>
        <ButtonGroup>
          {button1}
          {button2}
        </ButtonGroup>
      </div>

      <HideMainNav />
    </div>
  );
};

export default ErrorPage;
