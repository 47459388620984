import { Button } from "@flixbus/honeycomb-react";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import buildPath from "../../../../routes/buildPath/buildPath";
import { MemoizedTooltipPortal } from "../../../../shared/components/tooltipPortal/TooltipPortal";
import { Schedule } from "../../../../shared/types/schema";
import * as css from "./ChunkOverlapTooltip.scss";

export type ChunkOverlapTooltipProps = {
  schedules: Array<Pick<Schedule, "id" | "name">>;
};

const ChunkOverlapTooltip: React.FC<ChunkOverlapTooltipProps> = ({ schedules }) => {
  const navigate = useNavigate();

  const [coords, setCoords] = React.useState({ left: 0, top: 0 });
  const [showTooltip, setShowTooltip] = React.useState(false);

  const mouseDown = ({ currentTarget }: React.MouseEvent<HTMLAnchorElement>) => {
    const rect = currentTarget.getBoundingClientRect();
    setCoords({
      left: rect.left + rect.width / 2,
      top: rect.top + rect.height,
    });
    setShowTooltip(true);
  };

  const mouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div className={css.wrapper} onMouseLeave={mouseLeave}>
      <Button display="square" extraClasses={css.button} onMouseDown={mouseDown}>
        +{schedules.length - 1}
      </Button>

      {showTooltip && (
        <MemoizedTooltipPortal
          invertedColors={false}
          positionX={coords.left}
          positionYTop={coords.top}
          positionYBottom={coords.top - 24}
        >
          <ul className={css.tooltipList}>
            {schedules.map((schedule) => (
              <li className={css.item} key={schedule.id}>
                <div onClick={() => navigate(buildPath.showScheduleDetail(schedule.id))}>
                  {schedule.name}
                </div>
              </li>
            ))}
          </ul>
        </MemoizedTooltipPortal>
      )}
    </div>
  );
};

export default ChunkOverlapTooltip;
