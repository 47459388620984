import { Grid, GridCol } from "@flixbus/honeycomb-react";
import { useField } from "formik2";
import * as React from "react";
import { useIntl } from "react-intl";
import CustomFormRow from "../../../../shared/components/customFormRow/CustomFormRow";
import FormikChoiceWrapper from "../../../../shared/components/formikChoiceWrapper/FormikChoiceWrapper";
import FormikSelect from "../../../../shared/components/formikSelect/FormikSelect";
import FormikSwitch from "../../../../shared/components/formikSwitch/FormikSwitch";
import FormikTimeUnitInput from "../../../../shared/components/formikTimeUnitInput/FormikTimeUnitInput";
import FormikTrueFalseSelect from "../../../../shared/components/formikTrueFalseSelect/FormikTrueFalseSelect";
import { createTranslatedOptionsFromEnum } from "../../../../shared/helpers/autocompleteOptions/createOptions";
import {
  DisabilitySupportRegistrationType,
  DisabilitySupportType,
  TimeUnit,
} from "../../../../shared/types/schema";

export type Props = {
  policyIndex: number;
  readOnly?: boolean;
};

const PassengerWithDisabilityForm: React.FC<Props> = ({ policyIndex, readOnly }) => {
  const { formatMessage } = useIntl();
  const NAME = `policies[${policyIndex}].policy.passengerWithDisability`;
  const SPECIFICATIONS_NAME = `${NAME}.passengerWithDisabilitySpecification`;

  const [, meta] = useField(NAME);

  return (
    <>
      <CustomFormRow>
        <FormikTrueFalseSelect
          label={formatMessage({ id: "lineForm.passengerWithDisabilityPolicy.label" })}
          name={`${NAME}.supported`}
          trueLabel={formatMessage({ id: "general.yes" })}
          falseLabel={formatMessage({ id: "general.no" })}
          isCategoryTitle
          readOnly={readOnly}
        />
      </CustomFormRow>
      {meta.value.supported && (
        <>
          <CustomFormRow>
            <FormikChoiceWrapper
              name={`${SPECIFICATIONS_NAME}.supportingServices`}
              label={formatMessage({ id: "general.allowedOptions.headline" })}
              isMulti
              options={createTranslatedOptionsFromEnum(
                DisabilitySupportType,
                "lineForm.passengerWithDisabilityPolicy.supportingServices.option"
              )}
              readOnly={readOnly}
            />
          </CustomFormRow>
          <CustomFormRow>
            <Grid>
              <GridCol size={5}>
                <FormikSelect
                  name={`${SPECIFICATIONS_NAME}.registrationInstruction`}
                  label={formatMessage({
                    id: "lineForm.passengerWithDisabilityPolicy.registrationInstruction.label",
                  })}
                  options={createTranslatedOptionsFromEnum(
                    DisabilitySupportRegistrationType,
                    "lineForm.passengerWithDisabilityPolicy.registrationInstruction.option"
                  )}
                  readOnly={readOnly}
                  placeholder={formatMessage({
                    id: "lineForm.passengerWithDisabilityPolicy.registrationInstruction.placeholder",
                  })}
                />
              </GridCol>
              {meta.value.passengerWithDisabilitySpecification.registrationInstruction &&
                meta.value.passengerWithDisabilitySpecification
                  .registrationInstruction !==
                  DisabilitySupportRegistrationType.NoRegistrationNeeded && (
                  <GridCol size={3}>
                    <FormikTimeUnitInput
                      name={`${SPECIFICATIONS_NAME}.registrationUntil`}
                      label={formatMessage({
                        id: "lineForm.policies.bookableUntil.label",
                      })}
                      info={formatMessage({ id: "lineForm.policies.bookableUntil.info" })}
                      defaultUnit={TimeUnit.Hours}
                      optionalUnit={TimeUnit.Days}
                      readOnly={readOnly}
                    />
                  </GridCol>
                )}
            </Grid>
          </CustomFormRow>
          <CustomFormRow>
            <FormikSwitch
              name={`${SPECIFICATIONS_NAME}.proofOfDisabilityRequired`}
              label={`${formatMessage({
                id: "lineForm.passengerWithDisabilityPolicy.proofOfDisabilityRequired.label",
              })} (${formatMessage({
                id: "lineForm.passengerWithDisabilityPolicy.proofOfDisabilityRequired.info",
              })})`}
              readOnly={readOnly}
              small
            />
          </CustomFormRow>
        </>
      )}
    </>
  );
};

export default PassengerWithDisabilityForm;
