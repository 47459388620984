import { Divider, Grid, GridCol } from "@flixbus/honeycomb-react";
import { useField } from "formik2";
import * as React from "react";
import { useIntl } from "react-intl";
import CustomFormRow from "../../../../shared/components/customFormRow/CustomFormRow";
import FormikInput from "../../../../shared/components/formikInput/FormikInput";
import FormikTrueFalseSelect from "../../../../shared/components/formikTrueFalseSelect/FormikTrueFalseSelect";
import FormikUnitInput from "../../../../shared/components/formikUnitInput/FormikUnitInput";
import * as css from "../../styles/PolicyStyles.scss";
import { IncludedLuggageFormValues } from "../../types";

type Props = {
  name: string;
  label: string;
  readOnly?: boolean;
};

const IncludedLuggageInputs: React.FC<Props> = ({ name, label, readOnly }) => {
  const { formatMessage } = useIntl();
  const [, meta] = useField<IncludedLuggageFormValues>(name);

  return (
    <div>
      <CustomFormRow data-id="yesNo-customFormRow">
        <FormikTrueFalseSelect
          name={`${name}.inTicketIncluded`}
          label={label}
          trueLabel={formatMessage({ id: "general.yes" })}
          falseLabel={formatMessage({ id: "general.no" })}
          readOnly={readOnly}
          isCategoryTitle
        />
      </CustomFormRow>
      {meta.value.inTicketIncluded && (
        <div>
          <CustomFormRow>
            <Grid>
              <GridCol size={3}>
                <FormikUnitInput
                  name={`${name}.luggageSpecification.maxWeight`}
                  label={formatMessage({ id: "policies.maximumWeight.label" })}
                  maxLength={3}
                  readOnly={readOnly}
                />
              </GridCol>
              <GridCol size={3}>
                <FormikUnitInput
                  name={`${name}.luggageSpecification.maxDimension`}
                  label={formatMessage({ id: "policies.maxDimension.label" })}
                  info={formatMessage({ id: "policies.maxDimension.info" })}
                  readOnly={readOnly}
                />
              </GridCol>
              <GridCol size={3}>
                <FormikInput
                  name={`${name}.luggageSpecification.maxPieces`}
                  label={formatMessage({ id: "policies.maxPieces.label" })}
                  type="number"
                  readOnly={readOnly}
                  readOnlyValueWhenEmpty={formatMessage({ id: "general.notAvailable" })}
                />
              </GridCol>
            </Grid>
          </CustomFormRow>
        </div>
      )}
      <Divider extraClasses={css.inputDivider} />
    </div>
  );
};

export default IncludedLuggageInputs;
