import { Heading } from "@flixbus/honeycomb-react";
import { Feature, hasUserPermission } from "@flixbus-phx/marketplace-common";
import image from "@flixbus-phx/marketplace-common/assets/illustrations/passenger-sleeping.svg";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import ScheduleAddButton from "../scheduleAddButton/ScheduleAddButton";
import * as css from "./NoSchedulesFoundMessage.scss";

type Props = {
  lineId: string;
  noActiveSchedules?: boolean;
};

const NoSchedulesFoundMessage: React.FC<Props> = ({
  lineId,
  noActiveSchedules = false,
}) => {
  return (
    <div className={css.wrapper}>
      <div className={css.header}>
        <Heading size={3} flushSpace>
          <FormattedMessage id="lineDetail.schedules.info.noSchedules" />
        </Heading>
      </div>

      {hasUserPermission(Feature.CREATE_SCHEDULE) && !noActiveSchedules && (
        <div className={css.button}>
          <ScheduleAddButton lineId={lineId} />
        </div>
      )}

      <img src={image} alt="" width="350" height="287" />
    </div>
  );
};

export default NoSchedulesFoundMessage;
