import { useRef, useEffect } from 'react';

/**
 * Creates a new Root Element to be used as Portal root.
 * @param {string} id
 * @returns {HTMLDivElement}
 */
const createRootElement = id => {
  const rootElement = document.createElement('div');
  rootElement.setAttribute('id', id);
  return rootElement;
};

/**
 * Creates a portal to a different root element.
 * @param {string} id The id of the target container.
 * @returns {HTMLElement} The DOM node to use as the Portal target.
 */
const usePortal = id => {
  const containerRef = useRef(null);
  useEffect(() => {
    const existingParent = document.getElementById(id);
    const parentElement = existingParent || createRootElement(id);
    if (!existingParent) {
      document.body.appendChild(parentElement);
    }
    parentElement.appendChild(containerRef.current);
    return () => {
      containerRef.current.remove();

      // only remove if the element was created with the hook
      if (!existingParent && !parentElement.childElementCount) {
        parentElement.remove();
      }
    };
  }, [id]);
  const getContainerElem = () => {
    if (!containerRef.current) {
      containerRef.current = document.createElement('div');
    }
    return containerRef.current;
  };
  return getContainerElem();
};

export { usePortal as default };
