import { HeaderSubNavigation, NavItem } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Feature, hasUserPermission, logout } from "../../../auth";
import {
  commonPathnames,
  getUserPreferredLanguage,
  setUserPreferredLanguage,
  spaLocales,
  toggleThemeSetting,
} from "../../../helper";
import LanguageSwitcher from "../../languageSwitcher/LanguageSwitcher";

const getWidgetNavigation = () => {
  const { formatMessage } = useIntl();
  const selected = getUserPreferredLanguage();
  const locale = spaLocales.find((lang) => lang.locale === selected);

  return (
    <HeaderSubNavigation id="user-sub-menu">
      {hasUserPermission(Feature.USER_HAS_SERVICEDESK_ACCESS) ? (
        <NavItem
          key={commonPathnames.serviceDeskReportProblem}
          href={commonPathnames.serviceDeskReportProblem}
          target="_blank"
        >
          <FormattedMessage id="navigation.reportProblem" />
        </NavItem>
      ) : (
        <></>
      )}
      {hasUserPermission(Feature.USER_HAS_MUS_ACCESS) ? (
        <NavItem key="PersonalSettings" href={commonPathnames.mus}>
          <FormattedMessage id="navigation.personalSettings" />
        </NavItem>
      ) : (
        <></>
      )}
      <NavItem key="toggleTheme" onClick={toggleThemeSetting} Elem="button">
        <FormattedMessage id="navigation.toggleTheme" />
      </NavItem>
      {locale ? (
        <NavItem key="switchLanguage" Elem="div">
          <LanguageSwitcher
            currentLanguage={locale}
            languages={[
              {
                region: formatMessage({ id: "navigation.switchLanguage" }),
                locales: spaLocales,
              },
            ]}
            onLanguageSelected={setUserPreferredLanguage}
          />
        </NavItem>
      ) : (
        <></>
      )}
      <NavItem key="MobilityPartnerPortal" href={commonPathnames.mpp} Elem="a">
        <FormattedMessage id="navigation.MobilityPartnerPortal" />
      </NavItem>
      <NavItem key="logout" onClick={logout} Elem="button">
        <FormattedMessage id="navigation.logout" />
      </NavItem>
    </HeaderSubNavigation>
  );
};

export default getWidgetNavigation;
