import * as React from "react";
import { useIntl } from "react-intl";
import formatErrorMessage from "../../helper/formatErrorMessage/formatErrorMessage";
import ContactUsLink from "../contactUsLink/ContactUsLink";
import InfoAlert from "../infoAlert/InfoAlert";

type Props = {
  message: string;
  type?: "warning" | "danger" | "info";
};

const DefaultInfoAlert: React.FC<Props> = ({ message, type = "danger" }) => {
  const { formatMessage } = useIntl();

  return (
    <InfoAlert type={type}>
      <>
        {`${formatErrorMessage(message)}. ${formatMessage({ id: "general.info.tryAgain" })} `}
        <ContactUsLink />
      </>
    </InfoAlert>
  );
};

export default DefaultInfoAlert;
