import { Feature, hasUserPermission } from "@flixbus-phx/marketplace-common";
import { DisplayedScheduleStatus } from "../../types/schema";
import AggregatedScheduleStatus from "./types";

export default (scheduleStatus: DisplayedScheduleStatus): AggregatedScheduleStatus => {
  switch (scheduleStatus) {
    case DisplayedScheduleStatus.WorkInProgress:
    case DisplayedScheduleStatus.CreatedViaApi:
      return AggregatedScheduleStatus.DRAFT;
    case DisplayedScheduleStatus.InReview:
      return AggregatedScheduleStatus.IN_REVIEW;
    case DisplayedScheduleStatus.Approved:
    case DisplayedScheduleStatus.InGeneration:
    case DisplayedScheduleStatus.Published:
    case DisplayedScheduleStatus.Accepted:
      return AggregatedScheduleStatus.PROCESSING;
    case DisplayedScheduleStatus.PricesPublished:
      return hasUserPermission(Feature.VIEW_SCHEDULE_DETAILED_STATUS)
        ? AggregatedScheduleStatus.HIDDEN
        : AggregatedScheduleStatus.PROCESSING;
    case DisplayedScheduleStatus.Hidden:
      return hasUserPermission(Feature.VIEW_HIDDEN_STATUS)
        ? AggregatedScheduleStatus.HIDDEN
        : AggregatedScheduleStatus.PROCESSING;
    case DisplayedScheduleStatus.OnSale:
    case DisplayedScheduleStatus.PartiallyOnSale:
      return AggregatedScheduleStatus.ON_SALE;
    case DisplayedScheduleStatus.Archived:
    case DisplayedScheduleStatus.OutOfSale:
      return AggregatedScheduleStatus.INACTIVE;
    default:
      return AggregatedScheduleStatus.INACTIVE;
  }
};
