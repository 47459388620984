import { IconTripSolid } from "@flixbus/honeycomb-icons-react";
import { Button } from "@flixbus/honeycomb-react";
import {
  addNotification,
  DefaultInfoAlert,
  Feature,
  formatErrorMessage,
  hasUserPermission,
  NotificationType,
  readURIParams,
} from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import buildPath from "../../routes/buildPath/buildPath";
import DeleteButton from "../../shared/components/deleteButton/DeleteButton";
import DetailSummary from "../../shared/components/detailSummary/DetailSummary";
import DetailSummarySkeleton from "../../shared/components/detailSummarySkeleton/DetailSummarySkeleton";
import FormikButtons from "../../shared/components/formikButtons/FormikButtons";
import HorizontalNavigation, {
  HorizontalNavigationDisplayedTab,
} from "../../shared/components/horizontalNavigation/HorizontalNavigation";
import getEditableLineFields from "../../shared/helpers/getEditableFormFields/getEditableLineFields/getEditableLineFields";
import stripTypename from "../../shared/helpers/stripTypename/stripTypename";
import LineCapacityAndAmenitiesForm from "../lineCapacityAndAmenitiesForm/LineCapacityAndAmenitiesForm";
import LineForbiddenRelationsForm from "../lineForbiddenRelationsForm/LineForbiddenRelationsForm";
import LineGeneralSettingsForm from "../lineGeneralSettingsForm/LineGeneralSettingsForm";
import * as css from "./LineDetail.scss";
import {
  UpdateLineMutationVariables,
  useDeleteLineMutation,
  useLineDetailsQuery,
  useUpdateLineMutation,
} from "./api/operations.generated";
import { generateLineDetailTags } from "./helpers/lineDetailHelper/lineDetailHelper";

type Props = {
  lineId: string;
};

const LineDetail: React.FC<Props> = ({ lineId }) => {
  const openForbiddenRelations =
    readURIParams(window.location.href, false).forbidden_relations === "true";
  const [tabDisplayed, setTabDisplayed] = React.useState(
    openForbiddenRelations
      ? HorizontalNavigationDisplayedTab.THREE
      : HorizontalNavigationDisplayedTab.ONE
  );
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const [editMode, setEditMode] = React.useState(openForbiddenRelations);

  const {
    data,
    loading: queryLoading,
    error: queryError,
  } = useLineDetailsQuery({
    variables: { lineId },
  });

  const [updateLine, { loading: updateLineLoading }] = useUpdateLineMutation({
    onError: (error) =>
      addNotification({
        message: `Line update failed: ${formatErrorMessage(error.message)}`,
        type: NotificationType.Danger,
      }),
    onCompleted: () => {
      addNotification({
        message: formatMessage({ id: "lineDetail.submit.notification.success" }),
      });
      setEditMode(false);
    },
  });

  const handleUpdate = (values: Partial<UpdateLineMutationVariables>) => {
    updateLine({
      variables: {
        id: lineId,
        ...values,
      },
    });
  };

  const [lineDelete] = useDeleteLineMutation({
    onError: (error) =>
      addNotification({
        message: `Line delete failed: ${formatErrorMessage(error.message)}`,
        type: NotificationType.Danger,
      }),
    onCompleted: () => {
      navigate(buildPath.showPartnerDetail(data?.findLine.partner.id as string));
    },
  });

  const navigationLabels = [
    {
      text: formatMessage({ id: "lineForm.tab.general" }),
      position: HorizontalNavigationDisplayedTab.ONE,
    },
    {
      text: formatMessage({ id: "lineForm.tab.capacityAndAmenities" }),
      position: HorizontalNavigationDisplayedTab.TWO,
    },
    {
      text: formatMessage({ id: "general.forbiddenRelations" }),
      position: HorizontalNavigationDisplayedTab.THREE,
    },
  ];

  if (queryLoading) {
    return <DetailSummarySkeleton />;
  }

  if (data?.findLine) {
    const line = data.findLine;

    const editableFields = getEditableLineFields(line.readOnly, line.deletable, false);

    const Buttons = editableFields.submitButton ? (
      <FormikButtons
        submitButtonText={formatMessage({ id: "lineDetail.submit.button" })}
        submitButtonLoading={updateLineLoading}
        submitButtonAppearance="primary"
        resetButtonText={formatMessage({ id: "general.cancel" })}
        resetButtonAppearance="secondary"
        disableSubmitWhenNotDirty
      >
        {editableFields.deleteButton.isShown && (
          <DeleteButton
            onDelete={() => lineDelete({ variables: { id: lineId } })}
            buttonText={formatMessage({ id: "line.delete" })}
            buttonTitle={formatMessage({ id: "lineDetail.delete.button.title" })}
            popupTitle={formatMessage({ id: "line.delete" })}
            popupText={formatMessage(
              { id: "lineDetail.delete.popup.text" },
              {
                networkCode: line.networkCode,
              }
            )}
            disabled={!editableFields.deleteButton.isEditable}
          />
        )}
      </FormikButtons>
    ) : (
      <div className={css.cancelButton} data-id="cancel-button">
        <Button appearance="primary" onClick={() => setEditMode(false)}>
          <FormattedMessage id="general.close" />
        </Button>
      </div>
    );

    return (
      <DetailSummary
        isEditMode={editMode}
        boxIcon={IconTripSolid}
        boxContent={{
          heading: line.networkCode,
          firstSubtitle: line.networkDescription,
          secondSubtitle: hasUserPermission(Feature.EDIT_LINE_CONTRACT_MODEL_UNPUBLISHED)
            ? {
                firstPart: formatMessage(
                  { id: "line.model" },
                  {
                    cooperationType: line.cooperationType,
                  }
                ),
              }
            : undefined,
        }}
        tags={generateLineDetailTags(line)}
        onOpen={() => setEditMode(true)}
        onClose={() => setEditMode(false)}
      >
        <HorizontalNavigation
          displayedTab={tabDisplayed}
          onTabClicked={(clickedTab) => setTabDisplayed(clickedTab)}
          labels={navigationLabels}
        />
        {tabDisplayed === HorizontalNavigationDisplayedTab.ONE && (
          <LineGeneralSettingsForm
            defaultValues={stripTypename({
              networkDescription: line.networkDescription,
              cooperationType: line.cooperationType,
              cutOffSales: line.cutOffSales,
              brandId: line.brand?.id,
              meansOfTransport: line.meansOfTransport,
              customNetworkCode: line.networkCode,
            })}
            onSubmit={(values) => handleUpdate({ generalSettings: values })}
            onCancel={() => setEditMode(false)}
            editableFields={editableFields.generalSettings}
            partnerId={line.partner.id}
            isUsedForCreation={false}
          >
            {Buttons}
          </LineGeneralSettingsForm>
        )}
        {tabDisplayed === HorizontalNavigationDisplayedTab.TWO && (
          <LineCapacityAndAmenitiesForm
            defaultValues={stripTypename({
              minSeatCapacity: line.minSeatCapacity,
              amenities: line.amenities,
              defaultCirculationId: line.defaultCirculation?.uuid,
            })}
            onSubmit={(values) => handleUpdate({ capacityAndAmenities: values })}
            onCancel={() => setEditMode(false)}
            editableFields={editableFields.capacityAndAmenities}
            additionallyRequiredFields={{ minSeatCapacity: line.readOnly }}
            partnerId={line.partner.id}
          >
            {Buttons}
          </LineCapacityAndAmenitiesForm>
        )}

        {tabDisplayed === HorizontalNavigationDisplayedTab.THREE && (
          <LineForbiddenRelationsForm
            defaultValues={line.forbiddenRelations.map((forRe) => {
              return stripTypename(forRe);
            })}
            onSubmit={(values) =>
              handleUpdate({
                forbiddenRelations: values.map((relation) => {
                  return {
                    fromId: relation.from.id,
                    toId: relation.to.id,
                  };
                }),
              })
            }
            onCancel={() => setEditMode(false)}
            editableFields={editableFields.forbiddenRelations}
          >
            {Buttons}
          </LineForbiddenRelationsForm>
        )}
      </DetailSummary>
    );
  }

  if (queryError) {
    return (
      <DefaultInfoAlert
        message={formatMessage({ id: "lineDetail.error.lineNotFound" }, { lineId })}
      />
    );
  }

  return <></>;
};

export default LineDetail;
