import PropTypes__default from 'prop-types';

var InlineIconPropType = PropTypes__default.oneOfType([PropTypes__default.func, PropTypes__default.shape({
  regular: PropTypes__default.func.isRequired,
  active: PropTypes__default.func.isRequired
})]);
var InnerRefPropType = PropTypes__default.oneOfType([PropTypes__default.func, PropTypes__default.shape({
  current: PropTypes__default.object
})]);

export { InlineIconPropType, InnerRefPropType };
