import cx from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";
import buildPath from "../../../../routes/buildPath/buildPath";
import getFirstActiveSchedule from "../../helper/getFirstActiveSchedule/getFirstActiveSchedule";
import { CalendarSchedule } from "../../types";
import ChunkOverlapTooltip from "../chunkOverlapTooltip/ChunkOverlapTooltip";
import * as css from "./ScheduleCell.scss";
import getCssClass from "./scheduleCellHelper";

export type ScheduleCellProps = {
  colStart: number;
  highlight: boolean;
  setHighlight: (highlight: boolean, positionX?: number, positionY?: number) => void;
  schedules: Array<CalendarSchedule>;
  isFirstOccurrence: boolean;
  cellWidth: number;
  isWeekend: boolean;
  isStart: boolean;
  isEnd: boolean;
  textBoxWidth: number;
  hasInvisibleConceptChanges: boolean;
};

const ScheduleCell: React.FC<ScheduleCellProps> = ({
  colStart,
  highlight,
  setHighlight,
  schedules,
  isFirstOccurrence,
  cellWidth,
  isWeekend,
  isStart,
  isEnd,
  textBoxWidth,
  hasInvisibleConceptChanges,
}) => {
  const handleMouseEnter = ({
    currentTarget,
    clientX,
  }: React.MouseEvent<HTMLDivElement>) => {
    const rect = currentTarget.getBoundingClientRect();
    setHighlight(true, clientX, rect.top + rect.height);
  };

  const handleMouseLeave = () => {
    setHighlight(false);
  };

  const hasOverlapTooltip = schedules.length > 1;

  let zIndex = 0;
  if (isStart) zIndex++;

  return (
    <div
      className={cx(
        css.scrollableColumn,
        isWeekend && css.weekend,
        hasInvisibleConceptChanges && css.hasInvisibleConceptChanges
      )}
      style={{
        width: `${cellWidth}px`,
        transform: `translateX(${colStart}px)`,
        zIndex,
      }}
    >
      {schedules.length ? (
        <>
          <Link
            className={css.scheduleLink}
            to={buildPath.showScheduleDetail(schedules[0].id)}
          >
            <div
              className={cx(
                css.scheduleCell,
                getCssClass(getFirstActiveSchedule(schedules)),
                isStart && css.start,
                isEnd && css.end,
                isFirstOccurrence && css.isFirst,
                highlight && css.highlight
              )}
            />

            {isStart && (
              <div
                className={cx(
                  css.scheduleName,
                  hasOverlapTooltip && css.hasOverlapTooltip
                )}
                style={{
                  width: `${textBoxWidth}px`,
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                data-id="schedule-name"
              >
                {isFirstOccurrence ? getFirstActiveSchedule(schedules).name : ""}
              </div>
            )}
          </Link>
          {hasOverlapTooltip && <ChunkOverlapTooltip schedules={schedules} />}
        </>
      ) : null}
    </div>
  );
};

export default ScheduleCell;
