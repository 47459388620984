@use "variables" as *;

.wrapper {
  margin-bottom: $spacing-3;
}

.labelWrapper {
  margin-bottom: $spacing-1;
}

.labelHeader {
  font-weight: $font-weight-semibold;
}

.labelTooltip {
  font-weight: $font-weight-normal;
}

.toolTipIcon {
  font-weight: $font-weight-normal;
  color: $content-secondary-color;
  margin-left: $spacing-1;
}

.labelDescription {
  font-size: $font-size-small;
  color: $content-secondary-color;
}