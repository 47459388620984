import * as React from "react";
import { createPortal } from "react-dom";

type Props = {
  portalId: "portal-root" | "calendar-expand-button-portal";
};

const Portal: React.FC<Props> = ({ portalId, children }) => {
  const portal = document.getElementById(portalId);
  const el = document.createElement("div");

  React.useEffect(() => {
    portal?.appendChild(el);
    return () => {
      portal?.removeChild(el);
    };
  }, [el, portal]);

  return createPortal(children, el);
};

export default Portal;
