import { Fieldset, Grid, GridCol } from "@flixbus/honeycomb-react";
import { useField } from "formik2";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import CustomFormRow from "../../../../shared/components/customFormRow/CustomFormRow";
import FormikInput from "../../../../shared/components/formikInput/FormikInput";
import FormikTrueFalseSelect from "../../../../shared/components/formikTrueFalseSelect/FormikTrueFalseSelect";
import UnaccompaniedMinorsCheckbox from "../unaccompaniedMinorsCheckbox/UnaccompaniedMinorsCheckbox";
import * as css from "./UnaccompaniedMinorsForm.scss";

export type LinePoliciesProps = {
  policyIndex: number;
  readOnly?: boolean;
};

const UnaccompaniedMinorsForm: React.FC<LinePoliciesProps> = ({
  policyIndex,
  readOnly,
}) => {
  const { formatMessage } = useIntl();
  const NAME = `policies[${policyIndex}].policy.unaccompaniedMinors`;
  const SPECIFICATIONS_NAME = `${NAME}.unaccompaniedMinorSpecification`;

  const [, meta] = useField(`${NAME}.allowed`);

  return (
    <>
      <CustomFormRow>
        <FormikTrueFalseSelect
          label={formatMessage({ id: "lineForm.unaccompaniedMinors.label" })}
          name={`${NAME}.allowed`}
          trueLabel={formatMessage({ id: "general.yes" })}
          falseLabel={formatMessage({ id: "general.no" })}
          isCategoryTitle
          readOnly={readOnly}
        />
      </CustomFormRow>
      {meta.value && (
        <>
          <CustomFormRow>
            <Grid>
              <GridCol size={3}>
                <FormikInput
                  label={formatMessage({
                    id: "policies.unaccompaniedMinorSpecification.minimumAge.label",
                  })}
                  name={`${SPECIFICATIONS_NAME}.minAgeYears`}
                  type="number"
                  readOnly={readOnly}
                />
              </GridCol>
            </Grid>
          </CustomFormRow>
          <div className={css.subHeadline}>
            <FormattedMessage id="general.allowedOptions.headline" />:
          </div>
          <Fieldset extraClasses={css.fieldset} itemSpacing="2">
            <UnaccompaniedMinorsCheckbox
              label={formatMessage({
                id: "policies.unaccompaniedMinorSpecification.allowedOptions.interconnections.label",
              })}
              name={`${SPECIFICATIONS_NAME}.interconnectionTravel`}
              readOnly={readOnly}
            />
            <UnaccompaniedMinorsCheckbox
              label={formatMessage({
                id: "policies.unaccompaniedMinorSpecification.allowedOptions.nightRides.label",
              })}
              name={`${SPECIFICATIONS_NAME}.nightTravel`}
              readOnly={readOnly}
            />
            <UnaccompaniedMinorsCheckbox
              label={formatMessage({
                id: "policies.unaccompaniedMinorSpecification.allowedOptions.internationalTravel.label",
              })}
              name={`${SPECIFICATIONS_NAME}.internationalTravel`}
              readOnly={readOnly}
            />
          </Fieldset>
        </>
      )}
    </>
  );
};

export default UnaccompaniedMinorsForm;
