import { MsalAuthenticationResult } from "@azure/msal-react";
import * as React from "react";
import { ErrorTypes } from "../../components/errors/ErrorTypes";
import ErrorPage from "../../components/errors/errorPage/ErrorPage";

const AuthErrorPage: React.ElementType<MsalAuthenticationResult> = () => {
  return <ErrorPage type={ErrorTypes.e401} />;
};

export default AuthErrorPage;
