import { localizeDate } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import ScheduleStatusTag from "../../../../shared/components/scheduleStatusTag/ScheduleStatusTag";
import ScheduleStatusTagContent from "../../../../shared/components/scheduleStatusTagContent/ScheduleStatusTagContent";
import { MemoizedTooltipPortal } from "../../../../shared/components/tooltipPortal/TooltipPortal";
import { Schedule } from "../../../../shared/types/schema";
import getScheduleOfCalendarById from "../../helper/getScheduleOfCalendarById/getScheduleOfCalendarById";
import { Calendar } from "../../types";
import * as css from "./ScheduleNameTooltip.scss";

type Props = {
  positionX: number;
  positionY: number;
  scheduleId: Schedule["id"];
  calendar: Calendar;
};

const ScheduleNameTooltip: React.FC<Props> = ({
  positionX,
  positionY,
  scheduleId,
  calendar,
}) => {
  const schedule = getScheduleOfCalendarById(calendar, scheduleId);

  if (schedule) {
    return (
      <MemoizedTooltipPortal
        invertedColors
        positionX={positionX}
        positionYTop={positionY}
        positionYBottom={positionY - 36}
        moveXpositionWithMouse
        // Force a rerender when the highlighted schedule changes in order to make TooltipPortal render the new schedule details
        key={schedule.id}
      >
        <div className={css.wrapper} data-id="schedule-name-tooltip">
          <div className={css.nameAndStatusWrapper}>
            <ScheduleStatusTag status={schedule.displayedScheduleStatus} isSmall>
              <ScheduleStatusTagContent status={schedule.displayedScheduleStatus} />
            </ScheduleStatusTag>
            <div data-id="schedule-name-tooltip-name">{schedule.name}</div>
          </div>
          <div>
            {schedule.onSalePeriods.map((period) => (
              <div data-id="schedule-name-tooltip-period" key={period.start}>
                {localizeDate(period.start)} - {localizeDate(period.end)}
              </div>
            ))}
          </div>
        </div>
      </MemoizedTooltipPortal>
    );
  }

  return <></>;
};

export default ScheduleNameTooltip;
