import React__default__default from 'react';
import focusableSelectors from '../../packages/focusable-selectors/index.js';

/** Handles the tab key when focus trap is set on a given container element.
 *
 * @param {boolean} active - Whether the the container is active/visible or not.
 * @param {HTMLElement | null} container - The container element to set the focus trap on.
 * @param {HTMLElement | null} target - The target element to return focus to when the container is
 * closed.
 * @param {boolean} skipInitialFocus - Whether to skip setting the initial focus when container is
 * opened. E.g.: if the component handles it on its own, like the SubMenu that can set the initial
 * focus on the last element.
 * @return An object containing the event handler for the tab key and the first and last interactive
 * elements.
 */
function useTabTrap(active, container, target, skipInitialFocus) {
  var isFirstRender = React__default__default.useRef(true);
  var _a = React__default__default.useState(),
    firstInteractiveElement = _a[0],
    setFirstInteractiveElement = _a[1];
  var _b = React__default__default.useState(),
    lastInteractiveElement = _b[0],
    setLastInteractiveElement = _b[1];
  /* Set focus to target element when popup is closed, but is not the first render. */
  React__default__default.useEffect(function () {
    if (!active && isFirstRender.current === false) {
      target === null || target === void 0 ? void 0 : target.focus();
    }
  }, [active]);
  /** Removes first render flag to set target focus. */
  React__default__default.useEffect(function () {
    isFirstRender.current = false;
  }, []);
  /* Set focus to first interactive element when popup is opened and finishes loading. */
  React__default__default.useEffect(function () {
    if (active && firstInteractiveElement && !skipInitialFocus) {
      firstInteractiveElement.focus();
    }
  }, [active, firstInteractiveElement, skipInitialFocus]);
  /* When component is active and not longer loading,
   * find the first and last interactive elements and store them. */
  React__default__default.useEffect(function () {
    if (active && container) {
      var allInteractiveElements = container.querySelectorAll(focusableSelectors.join(','));
      var firstElement = allInteractiveElements === null || allInteractiveElements === void 0 ? void 0 : allInteractiveElements.item(0);
      var lastElement = allInteractiveElements === null || allInteractiveElements === void 0 ? void 0 : allInteractiveElements.item(allInteractiveElements.length - 1);
      setFirstInteractiveElement(firstElement);
      setLastInteractiveElement(lastElement);
    }
  }, [active, container]);
  var handleTabKeyDown = React__default__default.useCallback(function (event) {
    var eventTarget = event.target;
    if (event.key !== 'Tab' || eventTarget !== firstInteractiveElement && eventTarget !== lastInteractiveElement) {
      return;
    }
    if (eventTarget === firstInteractiveElement && event.shiftKey) {
      event.preventDefault();
      lastInteractiveElement === null || lastInteractiveElement === void 0 ? void 0 : lastInteractiveElement.focus();
    }
    if (eventTarget === lastInteractiveElement && !event.shiftKey) {
      event.preventDefault();
      firstInteractiveElement === null || firstInteractiveElement === void 0 ? void 0 : firstInteractiveElement.focus();
    }
  }, [firstInteractiveElement, lastInteractiveElement]);
  return {
    handleTabKeyDown: handleTabKeyDown,
    firstInteractiveElement: firstInteractiveElement,
    lastInteractiveElement: lastInteractiveElement
  };
}

export { useTabTrap };
