import React__default__default from 'react';

function useScrollableTabIndex(active, loading, containerRef) {
  var _a, _b, _c, _d;
  var _e = React__default__default.useState((_b = (_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.scrollHeight) !== null && _b !== void 0 ? _b : 0),
    scrollHeight = _e[0],
    setScrollHeight = _e[1];
  var _f = React__default__default.useState((_d = (_c = containerRef.current) === null || _c === void 0 ? void 0 : _c.offsetHeight) !== null && _d !== void 0 ? _d : 0),
    offsetHeight = _f[0],
    setOffsetHeight = _f[1];
  var _g = React__default__default.useState(undefined),
    tabIndexValue = _g[0],
    setTabIndexValue = _g[1];
  React__default__default.useEffect(function () {
    if (active && !loading && containerRef.current) {
      setScrollHeight(containerRef.current.scrollHeight);
      setOffsetHeight(containerRef.current.offsetHeight);
    }
  }, [active, loading, containerRef.current]);
  React__default__default.useEffect(function () {
    var hasScroll = scrollHeight > offsetHeight;
    setTabIndexValue(hasScroll ? 0 : undefined);
  }, [scrollHeight, offsetHeight]);
  return tabIndexValue;
}

export { useScrollableTabIndex };
