import {
  Checkbox,
  Fineprint,
  Grid,
  GridCol,
  Heading,
  Divider,
} from "@flixbus/honeycomb-react";
import { useField } from "formik2";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { PartnerEditableFields } from "../../../../shared/helpers/getEditableFormFields/types";
import {
  BookingInformationField,
  PassengerInformationField,
  TripInformationField,
} from "../../../../shared/types/schema";
import {
  defaultBookingValues,
  defaultPassengerValues,
  defaultTripValues,
} from "../../helpers/bookingDataSelectorHelper/bookingDataSelectorHelper";
import BookingDataSelectorColumn from "../bookingDataSelecorColumn/BookingDataSelectorColumn";
import * as css from "./BookingReportsSection.scss";

type Props = {
  editableFields?: PartnerEditableFields["capacityAndReportsInput"];
};

const BookingReportsSection: React.FC<Props> = ({ editableFields }) => {
  const { formatMessage } = useIntl();
  const [, metaDataPrivacy, helperDataPrivacy] = useField("dataPrivacyContractSigned");
  const [, , helperBookingInformation] = useField("bookingInformationConfiguration");

  const handleChange = () => {
    if (metaDataPrivacy.value) {
      helperBookingInformation.setValue({
        bookingInformation: [],
        tripInformation: [],
        passengerInformation: [],
      });
    } else {
      helperBookingInformation.setValue({
        bookingInformation: defaultBookingValues,
        tripInformation: defaultTripValues,
        passengerInformation: defaultPassengerValues,
      });
    }
    helperDataPrivacy.setValue(!metaDataPrivacy.value);
  };

  return (
    <>
      <Checkbox
        label={formatMessage({ id: "partnerForm.bookingInfo.checkbox.label" })}
        id="data-privacy-contract-signed"
        value="data-privacy-contract-signed"
        checked={metaDataPrivacy.value}
        onChange={handleChange}
        disabled={!editableFields?.dataPrivacyContractSigned}
      />
      {metaDataPrivacy.value && (
        <div className={css.bookingReportConfiguration}>
          {editableFields?.bookingInformationConfiguration && (
            <div>
              <Divider extraClasses={css.divider} />
              <Heading size={4} extraClasses={css.heading}>
                <FormattedMessage id="partnerForm.bookingInfo.configuration.headline" />
                <br />
                <Fineprint extraClasses={css.fineprint}>
                  <FormattedMessage id="partnerForm.bookingInfo.info.configuration" />
                </Fineprint>
              </Heading>
              <Grid>
                <GridCol size={3}>
                  <Heading size={4} extraClasses={css.columnHeader}>
                    <FormattedMessage id="partnerForm.bookingInfo.bookingInformation.headline" />
                  </Heading>
                  <BookingDataSelectorColumn FieldEnum={BookingInformationField} />
                </GridCol>
                <GridCol size={3}>
                  <Heading size={4} extraClasses={css.columnHeader}>
                    <FormattedMessage id="partnerForm.bookingInfo.tripInformation.headline" />
                  </Heading>
                  <BookingDataSelectorColumn FieldEnum={TripInformationField} />
                </GridCol>
                <GridCol size={3}>
                  <Heading size={4} extraClasses={css.columnHeader}>
                    <FormattedMessage id="partnerForm.bookingInfo.passengerInformation.headline" />
                  </Heading>
                  <BookingDataSelectorColumn FieldEnum={PassengerInformationField} />
                </GridCol>
              </Grid>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default BookingReportsSection;
