import { Tag } from "@flixbus/honeycomb-react";
import { Feature, hasUserPermission } from "@flixbus-phx/marketplace-common";
import cx from "classnames";
import * as React from "react";
import { DisplayedScheduleStatus } from "../../types/schema";
import * as css from "./ScheduleStatusTag.scss";

type Props = {
  status: DisplayedScheduleStatus;
  isOutdated?: boolean;
  extraClasses?: string;
  isSmall: boolean;
};

const ScheduleStatusTag: React.FC<Props> = ({
  status,
  isOutdated = false,
  extraClasses,
  isSmall,
  children,
}) => {
  const restProps = {
    small: isSmall,
    extraClasses,
  };

  const getAppearance = () => {
    if (
      [DisplayedScheduleStatus.OutOfSale, DisplayedScheduleStatus.Archived].includes(
        status
      )
    )
      return undefined;
    return "success";
  };

  const isOutlined = () => {
    if (
      [
        DisplayedScheduleStatus.WorkInProgress,
        DisplayedScheduleStatus.InReview,
        DisplayedScheduleStatus.Approved,
        DisplayedScheduleStatus.InGeneration,
        DisplayedScheduleStatus.Published,
        DisplayedScheduleStatus.Accepted,
        DisplayedScheduleStatus.PricesPublished,
      ].includes(status)
    )
      return true;
    return undefined;
  };

  const isStatusHidden = () => {
    if (
      status === DisplayedScheduleStatus.PricesPublished &&
      hasUserPermission(Feature.VIEW_SCHEDULE_DETAILED_STATUS)
    ) {
      return true;
    }

    if (status === DisplayedScheduleStatus.Hidden) {
      return true;
    }

    return false;
  };

  return (
    <Tag
      disabled={isOutdated}
      appearance={getAppearance()}
      display={isOutlined() ? "outlined" : undefined}
      {...restProps}
      extraClasses={cx(restProps.extraClasses, isStatusHidden() && css.hiddenTag)}
    >
      {children}
    </Tag>
  );
};

export default ScheduleStatusTag;
