import { Link } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Feature, hasUserPermission } from "../../auth";
import contactEmail from "../../helper/contact/contactEmail";
import { commonPathnames } from "../../helper/pathnames/pathnames";

const ContactUsLink: React.FC = () => {
  const contactLink = hasUserPermission(Feature.USER_HAS_SERVICEDESK_ACCESS)
    ? commonPathnames.serviceDeskReportProblem
    : `mailto:${contactEmail}`;
  return (
    <Link href={contactLink} target="_blank" rel="noreferrer">
      <FormattedMessage id="general.contactUs" />
    </Link>
  );
};

export default ContactUsLink;
