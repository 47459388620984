import { SelectGroup } from "@flixbus/honeycomb-react";
import { useField } from "formik2";
import * as React from "react";
import { useIntl } from "react-intl";
import * as css from "./FormikTrueFalseSelect.scss";

type Props = {
  name: string;
  label?: string;
  trueLabel: string;
  falseLabel: string;
  readOnly?: boolean;
  isCategoryTitle?: boolean;
  disableClick?: boolean;
};

const FormikTrueFalseSelect: React.FC<Props> = ({
  name,
  label,
  trueLabel,
  falseLabel,
  readOnly,
  isCategoryTitle,
  disableClick,
}) => {
  const [, meta, helper] = useField(name);
  const { formatMessage } = useIntl();

  const getReadOnlyValue = () => {
    if (meta.value === true) return trueLabel;
    if (meta.value === false) return falseLabel;
    return formatMessage({ id: "general.notAvailable" });
  };

  const handleClick = (e: React.MouseEvent) => {
    if ((e.target as HTMLInputElement).checked) {
      helper.setValue(undefined);
    }
    helper.setTouched(true);
  };

  const handleChange = (value: boolean) => {
    helper.setValue(value);
    // https://github.com/formium/formik/issues/2083#issuecomment-571259235
    helper.setTouched(true, false);
  };

  const options = [
    {
      id: `${name}-true`,
      value: `${name}-true`,
      name: `${name}-trueFalseSelect`,
      label: trueLabel,
      onBlur: () => helper.setTouched(true),
      onClick: disableClick ? undefined : handleClick,
      onChange: () => handleChange(true),
      checked: meta.value === true,
    },
    {
      id: `${name}-false`,
      value: `${name}-false`,
      name: `${name}-trueFalseSelect`,
      label: falseLabel,
      onBlur: () => helper.setTouched(true),
      onClick: disableClick ? undefined : handleClick,
      onChange: () => handleChange(false),
      checked: meta.value === false,
    },
  ];

  return (
    <div className={css.wrapper}>
      {label && (
        <div
          data-id="select-label"
          className={`${css.labelWrapper} ${isCategoryTitle && css.isCategoryTitle}`}
        >
          {label}
        </div>
      )}
      {readOnly ? (
        <>
          <div data-id="select-readOnly-value">{getReadOnlyValue()}</div>
        </>
      ) : (
        <SelectGroup
          aria-label={label || `${name}-label`}
          options={options}
          error={!!(meta.error && meta.touched)}
          infoError={meta.error}
        />
      )}
    </div>
  );
};

export default FormikTrueFalseSelect;
