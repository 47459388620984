var name = "@flixbus/honeycomb-react";
var version = "11.6.1";
var description = "React presentational components for Honeycomb styleguide";
var main = "./dist/dev/cjs/lib.js";
var module = "./dist/dev/esm/lib.js";
var sideEffects = false;
var types = "./typings/index.d.ts";
var scripts = {
	"bundle:amd": "sass --no-source-map --load-path=node_modules --load-path=. src/scss/honeycomb-themes.scss dist/css/honeycomb-themes.css && rollup -c ./conf/rollup.config.amd.js && PROD_BUILD=true rollup -c ./conf/rollup.config.amd.js",
	"bundle:cjs": "sass --no-source-map --load-path=node_modules --load-path=. src/scss/honeycomb-themes.scss dist/css/honeycomb-themes.css && rollup -c ./conf/rollup.config.cjs.js && PROD_BUILD=true rollup -c ./conf/rollup.config.cjs.js",
	"bundle:css": "npm run copy:assets && sass --no-source-map --load-path=node_modules --load-path=. src/scss/honeycomb-base.scss dist/css/honeycomb-base.css && sass --no-source-map --load-path=node_modules --load-path=. src/scss/honeycomb-helpers.scss dist/css/honeycomb-helpers.css && sass --no-source-map --load-path=node_modules --load-path=. src/scss/honeycomb-themes.scss dist/css/honeycomb-themes.css && sass --no-source-map --load-path=node_modules --load-path=. src/scss/honeycomb-tools.scss dist/css/honeycomb-tools.css",
	"bundle:esm": "sass --no-source-map --load-path=node_modules --load-path=. src/scss/honeycomb-themes.scss dist/css/honeycomb-themes.css && rollup -c ./conf/rollup.config.esm.js && PROD_BUILD=true rollup -c ./conf/rollup.config.esm.js",
	"bundle:iife": "sass --no-source-map --load-path=node_modules --load-path=. src/scss/honeycomb-themes.scss dist/css/honeycomb-themes.css && rollup -c ./conf/rollup.config.iife.js && PROD_BUILD=true rollup -c ./conf/rollup.config.iife.js",
	bundle: "export NODE_ENV=production && npm run bundle:css && npm run bundle:esm && npm run bundle:cjs && npm run bundle:iife && npm run bundle:amd",
	"copy:assets": "mkdir -p ./dist && cp -r ./src/scss/ ./dist/scss/",
	"lint:js": "eslint src --ext .jsx --ext .js --ext .tsx --fix --ext .ts --fix --cache",
	"lint:scss": "stylelint 'src/**/*.scss'",
	lint: "npm run lint:js && npm run lint:scss",
	playground: "webpack-dev-server --hot --config ./conf/webpack.playground.js",
	prepare: "husky",
	prepublishOnly: "npm run bundle",
	start: "npm run styleguide:dev",
	"styleguide:build": "export NODE_ENV=production && styleguidist build --config ./conf/styleguide.config.js",
	"styleguide:dev": "styleguidist server --config ./conf/styleguide.config.js",
	"test:axe:ci": "jest --config='./jest/jest-axe.config.ts' --ci --coverage",
	"test:axe": "jest --config='./jest/jest-axe.config.ts'",
	"test:ci": "jest --config='./jest/jest.config.ts' --ci --coverage",
	"test:watch": "jest --config='./jest/jest.config.ts' --watch --verbose --maxWorkers=25%",
	test: "jest --config='./jest/jest.config.ts' --no-cache"
};
var repository = {
	type: "git",
	url: "https://git.flix.tech/user/ui/honeycomb-react"
};
var keywords = [
	"honeycomb",
	"react",
	"styleguide"
];
var author = "Team Hive";
var license = "ISC";
var dependencies = {
	"@flixbus/honeycomb": "^13.4.1",
	"@flixbus/honeycomb-icons-react": "^8.0.0"
};
var devDependencies = {
	"@babel/core": "^7.26.0",
	"@babel/polyfill": "^7.12.1",
	"@babel/preset-env": "^7.26.0",
	"@babel/preset-react": "^7.25.9",
	"@rollup/plugin-babel": "^6.0.4",
	"@rollup/plugin-json": "^6.1.0",
	"@rollup/plugin-terser": "^0.4.4",
	"@testing-library/jest-dom": "^6.6.3",
	"@testing-library/react": "^16.0.1",
	"@testing-library/user-event": "^14.5.2",
	"@types/jest": "^29.5.14",
	"@types/jest-axe": "^3.5.9",
	"@types/node": "^18.19.67",
	"@types/react": "^18.3.12",
	"@types/react-dom": "^18.3.1",
	"@typescript-eslint/eslint-plugin": "^7.18.0",
	"@typescript-eslint/parser": "^7.18.0",
	autoprefixer: "^10.4.20",
	"babel-jest": "^29.7.0",
	"babel-loader": "^9.2.1",
	"babel-plugin-transform-react-remove-prop-types": "^0.4.24",
	"css-tree": "^3.0.1",
	"css-unique-classes-loader": "0.0.4",
	cssnano: "^7.0.6",
	"cssnano-preset-default": "^7.0.6",
	eslint: "^8.57.1",
	"eslint-config-airbnb": "^19.0.4",
	"eslint-plugin-import": "^2.31.0",
	"eslint-plugin-jest": "^27.6.3",
	"eslint-plugin-jsx-a11y": "^6.10.2",
	"eslint-plugin-react": "^7.37.2",
	"file-loader": "^6.2.0",
	"highlight.js": "^11.10.0",
	"html-webpack-plugin": "^5.6.3",
	husky: "^9.1.7",
	"identity-obj-proxy": "^3.0.0",
	jest: "^29.7.0",
	"jest-axe": "^9.0.0",
	"jest-environment-jsdom": "^29.7.0",
	"jest-junit": "^16.0.0",
	"lint-staged": "^15.2.10",
	"mini-css-extract-plugin": "^2.9.2",
	"path-browserify": "^1.0.1",
	postcss: "^8.4.49",
	"postcss-clean": "^1.2.2",
	"postcss-loader": "^8.1.1",
	"prop-types": "^15.8.1",
	react: "^18.3.1",
	"react-docgen-typescript": "^2.2.2",
	"react-dom": "^18.3.1",
	"react-router-dom": "^6.26.2",
	"react-styleguidist": "^13.1.3",
	"regenerator-runtime": "^0.14.1",
	rollup: "^4.27.4",
	"rollup-plugin-analyzer": "^4.0.0",
	"rollup-plugin-node-resolve": "^5.2.0",
	"rollup-plugin-postcss": "^4.0.2",
	"rollup-plugin-replace": "^2.2.0",
	"rollup-plugin-typescript2": "^0.36.0",
	sass: "^1.79.4",
	"sass-loader": "^16.0.3",
	stylelint: "^16.10.0",
	"stylelint-config-property-sort-order-smacss": "^10.0.0",
	"stylelint-config-standard-scss": "^13.1.0",
	"ts-jest": "^29.2.5",
	"ts-loader": "^9.5.1",
	"ts-node": "^10.9.2",
	tslib: "^2.8.1",
	typescript: "^5.7.2",
	webpack: "^5.96.1",
	"webpack-cli": "^5.1.4",
	"webpack-dev-server": "^5.1.0",
	"webpack-merge": "^6.0.1"
};
var peerDependencies = {
	"prop-types": "^15.8.1",
	react: "16.13.1 - 18",
	"react-dom": "16.13.1 - 18"
};
var browserslist = [
	"> 0.5%",
	"Safari > 12",
	"Chrome > 87",
	"Firefox > 52",
	"Firefox ESR"
];
var publishConfig = {
	registry: "https://flixbus.jfrog.io/flixbus/api/npm/fxt-line-ui-npm"
};
var hcPackage = {
	name: name,
	version: version,
	description: description,
	main: main,
	module: module,
	sideEffects: sideEffects,
	types: types,
	scripts: scripts,
	repository: repository,
	keywords: keywords,
	author: author,
	license: license,
	dependencies: dependencies,
	devDependencies: devDependencies,
	peerDependencies: peerDependencies,
	browserslist: browserslist,
	"lint-staged": {
	"src/**/*.{js,jsx,ts,tsx}": [
		"npm run --silent lint:js"
	],
	"src/**/*.scss": [
		"npm run --silent lint:scss"
	]
},
	publishConfig: publishConfig
};

export { author, browserslist, hcPackage as default, dependencies, description, devDependencies, keywords, license, main, module, name, peerDependencies, publishConfig, repository, scripts, sideEffects, types, version };
